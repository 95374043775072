<template>
  <div class="common">
    <div class="title gap-12">其他条款<a-alert v-if="isError" message="请完善必填条款" banner closable
        @close="showWarning = false" /></div>
    <a-form-model ref="technologyTerms" :model="clause" :rules="rules">
      <div>
        <div class="term">
          <div class="label">条款标题</div>
          <div>条款内容</div>
        </div>
        <div class="term">
          <div class="label"><span style="color: red;">*&ensp;</span>乙方权利与义务</div>
          <div v-if="!noAction" class="content">
            <a-form-model-item prop="secondPartyYearServiceTimes" required>
              乙方负责提供每年
              <a-input-number v-model="clause.secondPartyYearServiceTimes" :min="0" :step="1" :precision="0"
                placeholder="请输入" />&ensp;次设备的集中技术培训。
            </a-form-model-item>
          </div>
          <div v-else class="content">
            乙方负责提供每年{{ clause.secondPartyYearServiceTimes || '/' }}次设备的集中技术培训。</div>
        </div>
        <div class="term">
          <div class="label"><span style="color: red;">*&ensp;</span>违规责任</div>
          <div v-if="!noAction" class="content">
            <a-form-model-item
              prop="firstPartyOverduePaymentDay" required>甲方未按时、足额支付乙方任一期技术服务费或本合同项下其他款项或者逾期履行本协议其他义务超过<a-input-number
                v-model="clause.firstPartyOverduePaymentDay" :min="0" :step="1" :precision="0"
                placeholder="请输入" />&ensp;天。
            </a-form-model-item>
          </div>
          <div v-else class="content">甲方未按时、足额支付乙方任一期技术服务费或本合同项下其他款项或者逾期履行本协议其他义务超过{{ clause.firstPartyOverduePaymentDay
            ||
            '/' }}天。</div>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'TechnologyOtherTerms',
  props: {
    provideClause: {
      type: Object,
      default: () => ({
        secondPartyYearServiceTimes: undefined,
        firstPartyOverduePaymentDay: undefined,
      }),
    },
    noAction: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    provideClause: {
      handler (v) {
        this.clause = { ...v };
      },
      immediate: true,
    },
  },
  data () {
    return {
      clause: {
        secondPartyYearServiceTimes: undefined,
        firstPartyOverduePaymentDay: undefined,
      },
      /* message需要有空格或实体内容 */
      rules: {
        secondPartyYearServiceTimes: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
        firstPartyOverduePaymentDay: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
      },
      isError: false,
    };
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.technologyTerms.validate(async valid => {
          if (valid) {
            this.isError = false;
            this.$emit('update:provideClause', this.clause);
            resolve(valid);
          } else {
            this.isError = true;
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () { },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.common {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;

    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }

  .term {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 0.5px solid #F2F4F7;

    &:first-of-type {
      background-color: #F2F4F7;
    }

    .label {
      width: 130px;
      background-color: #F2F4F7;
      text-align: left;
    }

    .content {
      flex: 1;
      padding-left: 0.5em;
      text-align: left;

      &.other {
        display: flex;
        align-items: center;

        input {
          flex: 1;
        }

        .extra-content {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    >div {
      height: 40px;
      line-height: 40px;
      text-align: right;
      padding: 0 0.5em;
    }
  }
}
</style>

