<template>
  <div class="attachment">
    <div class="title gap-12"><span v-if="required" style="color: #f5222d; margin-right: 4px;">*</span>附件信息<a-alert
        v-if="required && showWarning" message="附件信息不能为空" banner closable @close="showWarning = false" /></div>
    <a-form-model :model="form" ref="attachmentInfo" :rules="rules">
      <xz-table :columns="attachmentColumns" :data="form.annexList" :rowKey="'url'" :editable="true">
        <template #buttons>
          <xzUpload v-if="!noAction" :showUploadList="false" :fileList.sync="form.annexList" :accept="accept" :uploadLimit="10"></xzUpload>
        </template>
        <span slot="businessType" slot-scope="text, record, index">
          <a-form-model-item v-if="!noAction" :prop="'annexList.' + index + '.businessType'" :rules="rules.businessType">
            <a-select v-model="record.businessType" placeholder="请选择" @change="(v) => handleNameChange(record, v)">
              <a-select-option v-for="opt in annexNameCache" :key="opt.optionName" :value="opt.optionCode">
                {{ opt.optionName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <span v-else>{{ mapName(text) }}</span>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a @click="deleteAttachment(index)">删除</a>
        </span>
        <span slot="url" slot-scope="text">
          <a :href="text" target="_blank">{{ text }}</a>
        </span>
      </xz-table>
    </a-form-model>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import xzUpload from '@/components/xiaozi-ui/xz-upload/src/upload.vue';
import { mapGetters } from 'vuex';

const attachmentColumns = [
  {
    title: '序号',
    key: 'index',
    width: 80,
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '附件名称',
    dataIndex: 'businessType',
    key: 'businessType',
    scopedSlots: { customRender: 'businessType' },
  },
  {
    title: '附件',
    dataIndex: 'url',
    key: 'url',
    scopedSlots: { customRender: 'url' },
  },
];

export default {
  name: 'AttachmentInfo',
  components: {
    xzTable,
    xzUpload,
  },
  props: {
    provideAnnexList: {
      type: Array,
      default: () => [],
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'annexNameCache',
    ]),
    attachmentColumns () {
      return this.noAction ? attachmentColumns : [
        ...attachmentColumns,
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ];
    },
  },
  watch: {
    provideAnnexList: {
      handler (v) {
        this.form.annexList = [...v];
      },
      immediate: true,
    },
  },
  data () {
    return {
      accept: '.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx,.jpeg,.jpg,.png',
      showWarning: false,
      form: {
        annexList: [],
      },
      rules: {
        businessType: [
          { required: false, message: '请选择', trigger: ['blur', 'change'] },
        ],
      },
    };
  },
  methods: {
    handleNameChange (record, v) {
      record.businessTypeDesc = this.mapName(v);
    },
    mapName (v) {
      const info = this.annexNameCache.find(annex => v === annex.optionCode);

      return info ? info.optionName : '';
    },
    deleteAttachment (index) {
      this.form.annexList.splice(index, 1);
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentInfo.validate(async valid => {
          if (valid) {
            this.$emit('update:provideAnnexList', this.form.annexList);
            resolve();
          } else {
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () { },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.attachment {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 12px;

    .ant-form-explain {
      display: none;
    }
  }

  /deep/ .ant-table-tbody>tr>td {
    padding: 8px 16px 0;
    overflow-wrap: break-word;
  }
}
</style>

