<script>
import render from '../render/render.js';
import TableLayout from '../render/tableRender';
import ChildFormRender from '../render/childFormRender';
import { getDefaultValueByTag, getDisplayValue, buildRules, flatternFields } from '../utils/component.js';
import _ from 'lodash';

const layouts = {
  colFormItem (h, scheme) {
    const { formConfCopy } = this;
    const config = scheme.__config__;
    if (this.fieldVisibleMap[config.renderKey] && !this.fieldVisibleMap[config.renderKey].calcValue) {
      return null;
    }
    const listeners = buildListeners.call(this, scheme);
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    let item = null;
    if (formConfCopy.disabled) {
      try {
        item = <span>{getDisplayValue(scheme, this.formModel)}</span>;
      } catch (error) {
        item = <render conf={scheme} on={listeners} formModel={this.formModel}/>;
      }
    } else {
      item = <render conf={scheme} on={listeners} formModel={this.formModel}/>;
    }
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label + '：' : ''} >
          {item}
        </el-form-item>
      </el-col>
    );
  },

  groupRowFormItem (h, scheme) {
    let child = renderChildren.apply(this, arguments);
    const config = scheme.__config__;
    return (
      <el-col span={scheme.span} >
        <content-block title={config.label}>
          <el-row gutter={scheme.gutter}>
            {child}
          </el-row>
        </content-block>
      </el-col>
    );
  },

  tableColumnFormItem (h, scheme) {
    const config = scheme.__config__;
    if (this.fieldVisibleMap[config.renderKey] && !this.fieldVisibleMap[config.renderKey].calcValue) {
      return null;
    }
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label : ''} >
          <TableLayout scheme={scheme} formModel={this.formModel}/>
        </el-form-item>
      </el-col>
    );
  },
  childFormItem (h, scheme) {
    const config = scheme.__config__;
    if (this.fieldVisibleMap[config.renderKey] && !this.fieldVisibleMap[config.renderKey].calcValue) {
      return null;
    }
    const listeners = buildListeners.call(this, scheme);
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = '0';
    return (
      <el-col span={config.span}>
        <el-form-item label-width={labelWidth} prop={config.renderKey.toString()}
          label={config.showLabel ? config.label : ''} >
          <ChildFormRender schema={scheme} formModel={this.formModel} on={listeners}/>
        </el-form-item>
      </el-col>
    );
  },
};

function renderFrom (h, slotdiv) {
  const { formConfCopy } = this;
  return formConfCopy ? (
    <el-row gutter={formConfCopy.gutter}>
      <el-form
        label-position={formConfCopy.labelPosition}
        disabled={formConfCopy.disabled}
        label-width={`${formConfCopy.labelWidth}px`}
        ref={formConfCopy.formRef}
        // model不能直接赋值 https://github.com/vuejs/jsx/issues/49#issuecomment-472013664
        props={{ model: this.formModel }}
        rules={this.formRules}
        class='w-100 custom_form_generator'
      >
        {formConfCopy.fields?.length && renderFormItem.call(this, h, formConfCopy.fields)}
        { slotdiv && viewformBtns.call(this, h, slotdiv)}
      </el-form>
    </el-row>
  ) : '';
}

const viewformBtns = (h, slotVNode) => {
  return <el-col>
    <el-form-item size="large">
      {slotVNode()}
    </el-form-item>
  </el-col>;
};

function renderFormItem (h, elementList) {
  let filter = t=>{
    if (t.__config__?.layout !== 'groupRowFormItem') {
      return t.__config__.visible !== false && (!this.fieldVisibleMap[t.__config__.renderKey] || this.fieldVisibleMap[t.__config__.renderKey].calcValue);
    }
    // 排除分组下面children都隐藏的分组渲染
    return t.__config__.children.some(c=>c.__config__.visible !== false && (!this.fieldVisibleMap[c.__config__.renderKey] || this.fieldVisibleMap[c.__config__.renderKey].calcValue));
  };
  return elementList?.length ? elementList.filter(filter).map(scheme => {
    const config = scheme.__config__;
    const layout = layouts[config.layout];

    if (layout) {
      return layout.call(this, h, scheme, elementList);
    }
    throw new Error(`没有与${config.layout}匹配的layout`);
  }) : [];
}

function renderChildren (h, scheme) {
  const config = scheme.__config__;
  if (!Array.isArray(config.children)) return null;
  return renderFormItem.call(this, h, config.children.filter(t=>t.__config__.visible !== false && (!this.fieldVisibleMap[t.__config__.renderKey] || this.fieldVisibleMap[t.__config__.renderKey].calcValue)));
}

function buildListeners (scheme) {
  const methods = this.formConf.__methods__ || {};
  const listeners = {};

  // 给__methods__中的方法绑定this和event
  Object.keys(methods).forEach(key => {
    listeners[key] = event => methods[key].call(this, event);
  });
  // 响应 render.js 中的 vModel $emit('input', val)
  listeners.input = event => {
    console.log(scheme.__config__.triggers);
    scheme.__config__.triggers && scheme.__config__.triggers.forEach(t=>{
      // eslint-disable-next-line no-new-func
      const func = new Function('formModel', 'formFieldMap', 'currentField ', 'currentValue ', `${t.code}`);
      func.call(this, this.formModel, this.fieldMap, scheme, event);
    });
    this.$set(this.formModel, scheme.__config__.renderKey, event);
    this.calcVisibleMap();
  };
  return listeners;
}

export default {
  components: {
    render,
    TableLayout,
    ChildFormRender,
  },
  props: {
    formConf: {
      type: Object,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
  },
  data () {
    const data = {
      formConfCopy: _.cloneDeep(this.formConf),
      formRules: {},
      fieldMap: {},
      fieldVisibleMap: {},
    };
    return data;
  },
  watch: {
    formConf: {
      immediate: true,
      handler (val) {
        this.formConfCopy = _.cloneDeep(val);
        this.formRules = {};
        this.flatternFields();
        this.processBindFilterParamAndValue();
        this.calcVisibleMap();
        buildRules(this.formConfCopy.fields, this.formRules, this.fieldMap);
      },
    },
  },
  methods: {
    resetForm () {
      this.formConfCopy = _.cloneDeep(this.formConf);
      this.$refs[this.formConf.formRef].resetFields();
    },
    validForm () {
      return this.$refs[this.formConf.formRef].validate();
    },
    flatternFields () {
      let fieldMap = {};
      this.fieldVisibleMap = {};
      flatternFields(this.formConfCopy.fields, fieldMap);
      this.fieldMap = fieldMap;
    },
    processBindFilterParamAndValue () {
      this.fieldMap && Object.keys(this.fieldMap).forEach(key=>{
        let fieldItem = this.fieldMap[key];
        if (fieldItem?.__config__?.from && fieldItem?.__config__?.from?.key) {
          this.$watch(`formModel.${fieldItem?.__config__?.from?.key}`, (val)=>{
            this.$set(this.formModel, key, fieldItem?.__config__?.from?.prop ? val[fieldItem?.__config__?.from?.prop] : val);
          });
          if (this.formModel[key] === null || this.formModel[key] === undefined || Object.keys(this.formModel[key]).length === 0) {
            const val = this.formModel[fieldItem?.__config__?.from?.key];
            if (val !== null && val !== undefined) {
              this.$set(this.formModel, key, fieldItem?.__config__?.from?.prop ? val[fieldItem?.__config__?.from?.prop] : val);
            }
          }
        }

        // 过滤筛选条件绑定对象变更时，重置当前控件绑定的值
        if (fieldItem?.__config__?.filterParam) {
          Object.keys(fieldItem?.__config__?.filterParam).forEach(f=>{
            let source = fieldItem?.__config__?.filterParam[f]?.source;
            if (source) {
              this.$watch(`formModel.${source.key}.${source.prop}`, ()=>{
                this.$set(this.formModel, fieldItem?.__config__?.renderKey, getDefaultValueByTag(fieldItem));
              });
            }
          });
        }
        // 记录受显隐控制的组件
        if (fieldItem?.__config__?.valueChangeAction) {
          fieldItem?.__config__?.valueChangeAction.forEach(t=>{
            if (t.checkedValue) {
              t?.bindFields?.forEach(f=>{
                if (this.fieldVisibleMap[f]) {
                  this.fieldVisibleMap[f].dependencies.push({
                    field: key,
                    fieldType: fieldItem.__config__.tag,
                    equalsValue: t.checkedValue,
                  });
                } else {
                  this.$set(this.fieldVisibleMap, f, {
                    calcValue: false,
                    dependencies: [{
                      field: key,
                      fieldType: fieldItem.__config__.tag,
                      equalsValue: t.checkedValue,
                    }],
                  });
                }
              });
            }
          });
        }
      });
    },
    calcVisibleMap () {
      Object.keys(this.fieldVisibleMap).forEach(key=>{
        const field = this.fieldVisibleMap[key];
        if (field) {
          this.$set(this.fieldVisibleMap, key, Object.assign(field, {
            calcValue: field.dependencies.some(t=>{
              if (t.fieldType === 'el-select') {
                if (t.equalsValue instanceof Array) {
                  return t.equalsValue.every(e=>{
                    return this.formModel[t.field] && this.formModel[t.field].some(s=>s.value === e.value);
                  });
                } else {
                  return t.equalsValue.value === this.formModel[t.field]?.value;
                }
              } else {
                if (t.equalsValue instanceof Array) {
                  return t.equalsValue.every(e=>{
                    return this.formModel[t.field] && this.formModel[t.field].some(s=>s === e);
                  });
                } else {
                  return t.equalsValue === this.formModel[t.field];
                }
              }
            }),
          }));
        }
      });
    },
  },
  render (h) {
    return renderFrom.call(this, h, this.$scopedSlots.footer);
  },
  created () {
    this.flatternFields();
    this.processBindFilterParamAndValue();
    this.calcVisibleMap();
    buildRules(this.formConfCopy.fields, this.formRules, this.fieldMap);
  },
};
</script>

<style lang="scss" scoped>
.custom_form_generator {
  /deep/.content_block {
    margin-bottom: 18px;
  }
  /deep/ .el-row {
    display: flex;
    flex-wrap: wrap;
    .el-col {
      flex-shrink: 0;
    }
  }
}
</style>
