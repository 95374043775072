<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">维修服务协议</div>
      <table border="1" class="dashed">
        <tr>
          <td>甲方(服务接收方)：</td>
          <td>{{ contractData.partA.name }}</td>
          <td>最终用户：{{ contractData.partA.finalCustomerName || '' }}</td>
        </tr>
        <tr>
          <td colspan="3">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;地址：{{ contractData.partA.address }}</td>
          <td>邮编：{{ contractData.partA.postCode }}</td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;电话：{{ contractData.partA.telephone }}</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;传真：{{ contractData.partA.fax }}</td>
          <td></td>
        </tr>
        <tr>
          <td>乙方(服务提供方)：</td>
          <td colspan="2">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td colspan="3">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;地址：{{ contractData.partB.address || '' }}</td>
          <td>邮编：{{ contractData.partB.postCode }}</td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;电话：{{ contractData.partB.telephone || '' }}</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2">&emsp;&emsp;传真：{{ contractData.partB.fax || '' }}</td>
          <td></td>
        </tr>
      </table>
      <div class="gap-top-2em indent-2em">甲乙双方经过平等协商，达成本协议(以下简称“协议”)，协议包括具体条款以及协议所提及的相关附件。</div>
      <div class="gap-top-2em title2">1.&emsp;服务内容：</div>
      <div class="indent-2em">1.1&ensp;设备概况</div>
      <table border="1">
        <thead>
          <tr>
            <th style="width: 50px;">序号</th>
            <th>设备名称</th>
            <th>设备型号</th>
            <th>设备品牌</th>
            <th>设备序列号</th>
            <th>故障描述</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in contractData.deviceList" :key="`device${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ d.deviceName }}</td>
            <td>{{ d.deviceModel }}</td>
            <td>{{ d.deviceBrandName }}</td>
            <td>{{ d.deviceSerialNumber }}</td>
            <td>{{ d.faultDescription }}</td>
          </tr>
        </tbody>
      </table>
      <div class="gap-top-1em indent-2em">1.2&ensp;计费清单</div>
      <table border="1">
        <thead>
          <tr>
            <th style="width: 50px;">序号</th>
            <th>计费类目</th>
            <th>计费项名称</th>
            <th>零备件号</th>
            <th>含税单价（元）</th>
            <th>数量</th>
            <th>质保期/保修期</th>
            <th>含税金额（元）</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in contractData.billList" :key="d.index">
            <td>{{ d.index }}</td>
            <td>{{ d.typeName }}</td>
            <td>{{ d.name }}</td>
            <td>{{ d.deviceRegisterCode }}</td>
            <td>{{ d.taxPrice }}</td>
            <td>{{ d.quantity }}</td>
            <td>{{ d.warrantyPeriod }} {{ d.warrantyPeriodUnit }}</td>
            <td>{{ d.taxAmount }}</td>
          </tr>
        </tbody>
      </table>
      <div class="indent-2em">
        零备件的保修期为：
        ____个月，以维修验收合格日起计。乙方保证该零备件符合厂家或厂家授权商的制造要求，无材料和使用上的缺陷。甲方需在更换后将原备件退还给乙方，若无特殊理由因甲方原因不能退还旧备件，甲方需另外支付该协议价的30%作为旧备件购买价。
      </div>
      <div class="gap-top-2em title2">2.&emsp;协议价款：</div>
      <div class="indent-2em">
        人工费用:{{ contractData.laborCostsPerYear }}元/年&emsp;(大写):{{ contractData.chineseLaborCostsPerYear }}。零备件:{{
          contractData.sparePartsCostPerYear }}元/年&emsp;(大写):
        {{ contractData.chineseSparePartsCostPerYear }}。
      </div>
      <div class="indent-2em">
        本合同总价（人民币）:{{ contractData.totalAmount }}元&emsp;&ensp;&emsp;(大写): {{ contractData.chineseTotalAmount }}。
      </div>
      <div class="indent-2em">
        以上价款为含税，税率{{ contractData.taxRate }}，如因国家相关规定导致税率产生变化，则以付款时适用的税率为准。
      </div>
      <div class="indent-2em">
        对于合同项目下涉及的零备件，如无特殊说明甲方需在更换后将原备件退还给乙方；未能归还的，甲方应当额外向乙方支付该备件采购金额的 30 % 。
      </div>
      <div class="gap-top-2em title2">3.&emsp;付款方式与发票：</div>
      <div class="indent-2em">3.1&ensp;经双方协商，采取以下方式付款：</div>
      <div v-if="contractData.paymentList.length === 1" class="indent-2em">合同签署后{{ contractData.paymentDays
      }}日内一次性支付全部价款。甲方应当按照合同约定将合同款项支付至交付乙方指定账户。</div>
      <template v-else>
        <div class="indent-2em">甲方按照如下约定分{{ contractData.paymentList.length || '' }}期向乙方支付合同款项：</div>
        <div v-for="(payment, index) in contractData.paymentList" class="indent-2em" :key="`payment${index}`">
          第{{ payment.index }}期：在{{ payment.paymentDateArray[0] }}年{{ payment.paymentDateArray[1] }}月{{
            payment.paymentDateArray[2] }}日前支付人民币{{ payment.paymentAmount
    || '' }}元；</div>
      </template>
      <div class="indent-2em">3.2&ensp;乙方指定账户信息如下：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="indent-2em">3.3&ensp;按照如下方式开具发票：</div>
      <div class="indent-2em">乙方每收到甲方支付的一笔款项后向甲方开具合规有效的等值发票（发票类型为：{{ contractData.invoiceTypeName }} ）。</div>
      <div class="indent-2em">甲方在收到乙方开具的合规有效的等值发票后（发票类型为：{{ contractData.invoiceTypeName }} ）按照双方约定向乙方支付货款。</div>
      <div class="gap-top-2em title2">4.&emsp;甲方的权利与义务：</div>
      <div class="indent-2em">
        <span style="position: absolute;">4.1&ensp;</span><div style="margin-left: 2em;display: inline-block;">甲方应积极配合乙方维修，包括但不限于向乙方详细提供设备（包括零备件）及故障说明，不得隐瞒，甲方提出维修需求，按乙方的要求准备服务场地，提供服务条件（包括但不限于供电电源、配套系统、用户线路等）。如因甲方故意隐瞒故障导致乙方维修成本的增加，甲方仍应承担该部分的费用。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.2&ensp;</span><div style="margin-left: 2em;display: inline-block;">甲方应当提供或协助提供服务所需的产品详细说明书、技术服务手册、检修报告及其它必要的文件，以便于乙方工程师进行设备服务。</div></div>
      <div class="indent-2em"><span style="position: absolute;">4.3&ensp;</span><div style="margin-left: 2em;display: inline-block;">本协议相关的化学品（如酒精，高压油等)以及产生的相关废物,甲方按照国家法律法规的要求进行管理和处置。</div></div>
      <div class="indent-2em"><span style="position: absolute;">4.4&ensp;</span><div style="margin-left: 2em;display: inline-block;">甲方需向乙方提供本协议服务设备的相关生产商或供应商的名称、联系方式。</div></div>
      <div class="gap-top-2em title2">5.&emsp;乙方的权利与义务：</div>
      <div class="indent-2em">
        <span style="position: absolute;">5.1&ensp;</span><div style="margin-left: 2em;display: inline-block;">乙方应当按协议约定向甲方提供维修服务；</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">5.2&ensp;</span><div style="margin-left: 2em;display: inline-block;">乙方维修时若发现原维修方案需要调整变更，应及时与甲方进行沟通，甲方对调整后的维修方案予以认可后，乙方方能继续服务。</div></div>
      <div class="indent-2em"><span style="position: absolute;">5.3&ensp;</span><div style="margin-left: 2em;display: inline-block;">甲方应当在乙方提供维修服务完成当天及时进行验收，逾期未验收的，视为验收合格。</div></div>
      <div class="gap-top-2em title2">6.&emsp;协议的变更和转让：</div>
      <div class="indent-2em">
        协议有效期内，双方不得擅自变更协议的权利和义务, 除非双方的授权代表以书面的方式确认。甲方同意且保证甲方利益的情况下，乙方可以将协议的部分或全部转让给其关联公司或第三方公司。
      </div>
      <div class="gap-top-2em title2">7.&emsp;违约责任：</div>
      <div class="indent-2em"><span style="position: absolute;">7.1&ensp;</span><div style="margin-left: 2em;display: inline-block;">除不可抗力或乙方不可能控制的因素导致的情形外，因为乙方无故不履行协议或者履行协议不符合约定给甲方造成损失的,乙方应当赔偿。在任何情况下，乙方在本协议项下所应承担的所有性质的责任的最大限额应以不超过乙方在本协议项下所收取的协议价格为限。并且乙方的责任范围不包括由于设备损害而导致的任何数据、信息、资料灭失、未能使用设备造成的甲方损失或甲方受到的任何性质的索赔以及甲方其它任何性质的间接损失或损害；</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">7.2&ensp;</span><div style="margin-left: 2em;display: inline-block;">如因甲方或其代表使用不当(如违反操作手册所载规程操作)等原因使乙方额外提供劳务或更换备件，乙方有权要求甲方承担因此发生的相关费用；</div></div>
      <div class="indent-2em"><span style="position: absolute;">7.3&ensp;</span><div style="margin-left: 2em;display: inline-block;">甲方应当按协议约定付款，逾期的，乙方有权暂停提供服务，该情形下造成的损失由甲方承担，如果甲方在本协议付款条款规定期限30天内还未支付相关款项，乙方有权中止或解除协议，并可要求甲方支付已经提供的服务费用、违约金（违约金按每日以协议总价格的0.3%算）及其他相关费用。在中止或解除协议之前，乙方将书面通知甲方。</div></div>
      <div class="gap-top-2em title2">8.&emsp;反腐败条款：</div>
      <div class="indent-2em"><span style="position: absolute;">8.1&ensp;</span><div style="margin-left: 2em;display: inline-block;">双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守下列各项规定。</div></div>
      <div class="indent-2em"><span style="position: absolute;">8.2&ensp;</span><div style="margin-left: 2em;display: inline-block;">任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。</div></div>
      <div class="indent-2em"><span style="position: absolute;">8.3&ensp;</span><div style="margin-left: 2em;display: inline-block;">若由于任何一方违反本协议关于反商业贿赂的约定而给对方造成任何损失，包括但不限于商誉损失、政府调查、罚款等，该方应对守约方承担赔偿责任。</div></div>
      <div class="gap-top-2em title2">9.&emsp;信息保密：</div>
      <div class="indent-2em">甲乙双方对本合同及本合同相关的其它信息、涉及乙方知识产权的相关信息均负有保密义务。未经另一方事先书面同意，任何一方不得将该信息的任何部分向任何第三方或社会公众披露。</div>
      <div class="gap-top-2em title2">10.&emsp;争议解决及法律适用：</div>
      <div class="indent-2em">双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。</div>
      <div class="gap-top-2em title2">11.&emsp;其他：</div>
      <div class="indent-2em">
        本协议经双方签字盖章生效，一式___份，甲方执___份，乙方执___份，双方所执签字盖章协议具有同等法律效力。</div>
      <div class="indent-2em">协议附件清单：（可删除没有的部分）。</div>
      <div v-if="!contractData.attachmentList.length" class="indent-2em">无</div>
      <div v-else v-for="(annex, index) in contractData.attachmentList" class="indent-2em" :key="`attach${index}`">{{ `${index + 1}、${annex.businessTypeDesc || '/'}` }}</div>
      <div class="indent-2em">
        本协议的附件是协议的有机组成部分，具有补充、解释或修改协议的作用，对双方有约束力。
      </div>
      <table border="1" class="gap-top-2em dashed">
        <tr height="150">
          <td class="title2">甲&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
          <td class="title2">乙&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center;">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SingleRepairContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  methods: {
    handlePrint () {
      const style = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {word-break: break-all;padding: 8px;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      word-break: break-all;
      padding: 8px;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}
</style>

