<script>
import DraggableResizeMixin from '@/mixins/draggableResizeMixin.js';

export default {
  name: 'XzTable',
  mixins: [DraggableResizeMixin],
  props: {
    toolbarConfig: {
      type: Object,
      default: () => { },
    },
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    styleAttrs: {
      type: Object,
      default: () => ({
        backgroundColor: '#ffffff',
      }),
    },
    rowKey: {
      type: [String, Function],
      default: (record, index) => index,
    },
    searchAttrs: {
      type: Object,
      default: () => ({
        show: false,
        placeholder: '请输入',
      }),
    },
    searchVal: {
      type: String,
      default: '',
    },
    searchAllowClear: {
      type: Boolean,
      default: false,
    },
    toolsAttrs: {
      type: Object,
      default: () => ({
        advancedSearch: false,
      }),
    },
    showPagination: {
      type: [Boolean, Object],
      default: false,
    },
    maskClosable: {
      type: Boolean,
      default: true,
    },
    drawerWidth: {
      type: Number,
      default: 400,
    },
    drawerStyle: {
      type: Object,
      default: () => ({
        display: 'flex',
        flexDirection: 'column',
      }),
    },
    bodyStyle: {
      type: Object,
      default: () => (
        {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }
      ),
    },
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    enableRowSelection: {
      type: Boolean,
      default: false,
    },
    selectionType: {
      type: String,
      default: 'checkbox',
    },
    locale: {
      type: Object,
    },
    aSlotsKey: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  render () {
    const on = {
      ...this.$listeners,
    };
    const props = { ...this.$attrs, ...this.$props, ...{ dataSource: this.data, columns: this.columns } };

    if (!props.locale) {
      const emptyImg = require('@/assets/empty.svg');

      props.locale = {
        emptyText: () => (<a-empty image={emptyImg} description={'暂无数据'}></a-empty>),
      };
    }

    const buttonSlots = this.$slots['buttons'];
    const otherSlots = this.$slots['others'];

    const aSlots = this.aSlotsKey.map(key => <span slot={key}>{this.$slots[key]}</span>);

    const search = [];
    if (this.searchAttrs.show) {
      search.push(<a-input-search value={this.searchVal} placeholder={this.searchAttrs.placeholder} style="width: 200px; margin-left: 8px;" allowClear={this.searchAllowClear} onChange={this.handleSearchChange.bind(this)} onSearch={this.handleSearch.bind(this)} />);
    }

    const tools = [];
    let drawer = null;
    if (this.toolsAttrs.refresh) {
      tools.push(<el-button icon="el-icon-refresh-left" onClick={this.handleRefresh.bind(this, true)}></el-button>);
    }
    if (this.toolsAttrs.zoom) {
      if (this.toolsAttrs.fullScreen) {
        tools.push(<el-button onClick={this.handleDetailFullScreen.bind(this, false)}><i class="iconfont-2 iconfont icon-suoxiao" style='font-size:10px;'></i></el-button>);
      } else {
        // icon="el-icon-full-screen"
        tools.push(<el-button onClick={this.handleDetailFullScreen.bind(this, true)}><i class="iconfont-2 iconfont fangda" style='font-size:12px;'></i></el-button>);
      }
    }
    if (this.toolsAttrs.advancedSearch) {
      tools.push(<el-button onClick={this.handleDrawer.bind(this, true)}><img src={require('@/assets/adSearch.svg')} style='width:14px;height:14px;'/></el-button>);

      drawer = <a-drawer
        title="高级筛选"
        closable={true}
        width={this.drawerWidth}
        drawerStyle={this.drawerStyle}
        bodyStyle={this.bodyStyle}
        visible={this.drawerVisible}
        maskClosable={this.maskClosable}
        onClose={this.handleDrawer.bind(this, false)}
      >
        {this.$slots['drawer']}
      </a-drawer>;
    }
    if (this.toolsAttrs.custom) {
      let custom = this.$slots['custom'];
      tools.push(custom);
    }

    let rowSelection = null;
    if (this.enableRowSelection) {
      rowSelection = {
        type: this.selectionType,
        onChange: (keys, rows) => {
          this.handleSelectChange(keys, rows);
        },
      };
    }

    const table = (
      <a-table ref='aTable' class={props.editable ? 'editable' : ''} props={props} scopedSlots={this.$scopedSlots} on={on} style={this.styleAttrs} pagination={this.showPagination} rowSelection={rowSelection} components={this.components}>
        {aSlots}
      </a-table>
    );
    return (
      <div class='xz-table-wrapper'>
        <div class="slot-wrapper">
          <div class="button-wrapper">
            {buttonSlots}
          </div>
          <div class="default">
            {otherSlots}
            {search}
            {tools}
          </div>
        </div>
        <div class="xz-table">
          {table}
        </div>
        {drawer}
      </div>
    );
  },
  data () {
    return {};
  },
  methods: {
    handleSearchChange (e) {
      this.$emit('update:searchVal', e.target.value);
    },
    handleSearch (v) {
      this.$emit('search', v);
    },
    handleDrawer (v) {
      this.$emit('update:drawerVisible', v);
    },
    handleSelectChange (keys, rows) {
      this.$emit('change', keys, rows);
    },
    handleRefresh () {
      this.$emit('refresh');
    },
    handleDetailFullScreen (flag) {
      this.$emit('fullScreen', flag);
    },
  },
};
</script>
<style scoped lang="scss">
.xz-table-wrapper {
  height: 100%;

  .slot-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .button-wrapper {
      button {
        margin-right: 12px;
      }
    }

    .default {
      flex: 1;
      text-align: right;

      button {
        margin-left: 8px;
        padding: 9px;
        width: 32px;
        height: 32px;
        &:first-of-type {
          margin-left: 16px;
        }
      }
    }
  }

  .editable {
    /deep/ .ant-form-item {
      margin: 0;

      .ant-form-explain {
        display: none;
      }
    }

    /deep/ .ant-table-tbody>tr>td {
      padding: 0 16px 0;
      overflow-wrap: break-word;
    }
  }

  /deep/ .ant-table-thead {
    &>tr>th {
      padding: 9px 16px;
      font-weight: bold;
      background-color: #F2F4F7;
    }
  }

  /deep/ .ant-table-tbody {
    tr {
      background-color: #fff;

      &>td {
        padding: 9px 16px;
        border-bottom-color: #EBEDF0;
      }
    }
  }

  /deep/ .ant-table-fixed-right {
    .ant-table-body-outer {
      padding-right: 0 !important;
    }

    .ant-table-thead {
      &>tr>th {
        padding: 9px 16px;
        font-weight: bold;
      }
    }

    .ant-table-tbody {
      tr {
        background-color: #fff;

        &>td {
          padding: 9px 16px;
          border-bottom-color: #EBEDF0;
        }
      }
    }
  }
}
</style>
