<template>
  <div class="bill_info_table">
    <a-form-model :model="form" ref="billInfoForm" :rules="rules">
      <xz-table :columns="columns" :data="form.billList" :scroll="{ x: 0, y: 540 }" :editable="true">
        <template #buttons>
          <a-button type="primary" icon="plus" @click="newBill">
            新增行
          </a-button>
        </template>
        <span slot="type" slot-scope="text, record, index">
          <a-form-model-item :prop="'billList.' + index + '.type'" :rules="rules.type">
            <a-select v-model="record.type" placeholder="请选择" style="width: 100%;"
              @change="(v) => handleChange(v, record)">
              <a-select-option v-for="opt in quotationItems" :key="opt.value" :value="opt.value">
                {{ opt.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </span>
        <span slot="name" slot-scope="text, record, index">
          <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.name'" :rules="rules.name">
            <a-input v-model="record.name" placeholder="请输入" />
          </a-form-model-item>
          <span v-else>/</span>
        </span>
        <span slot="deviceRegisterCode" slot-scope="text, record, index">
          <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.deviceRegisterCode'"
            :rules="rules.deviceRegisterCode">
            <a-input v-model="record.deviceRegisterCode" placeholder="请输入" />
          </a-form-model-item>
          <span v-else>/</span>
        </span>
        <span slot="taxPrice" slot-scope="text, record, index">
          <a-form-model-item :prop="'billList.' + index + '.taxPrice'" :rules="rules.taxPrice">
            <a-input-number v-model="record.taxPrice" placeholder="请输入" :min="0" :step="0.01" style="width: 100%;"
              :precision="2" @change="() => handleTotalMount(record)" />
          </a-form-model-item>
        </span>
        <span slot="quantity" slot-scope="text, record, index">
          <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.quantity'" :rules="rules.quantity">
            <a-input-number v-model="record.quantity" placeholder="请输入" :min="0" :step="1" style="width: 100%;"
              :precision="0" @change="() => handleTotalMount(record)" />
          </a-form-model-item>
          <span v-else>{{ record.quantity }}</span>
        </span>
        <span slot="unit" slot-scope="text, record, index">
          <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.unit'" :rules="rules.unit">
            <a-select v-model="record.unit" placeholder="请选择" style="width: 100%;">
              <a-select-option v-for="opt in measureUnitCache" :key="opt.optionName" :value="opt.optionName">
                {{ opt.optionName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <span v-else>{{ record.unit }}</span>
        </span>
        <span slot="warrantyPeriod" slot-scope="text, record, index">
          <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.warrantyPeriod'"
            :rules="rules.warrantyPeriod">
            <div style="display: flex;">
              <a-input-number v-model="record.warrantyPeriod" placeholder="请输入" :min="0" :step="0.01" style="width: 100%;"
                :precision="2" />
              <a-select v-model="record.warrantyPeriodUnit" placeholder="请选择" style="width: 100%; margin-left: 6px;">
                <a-select-option v-for="opt in qualityAssTypeCache" :key="opt.optionName" :value="opt.optionName">
                  {{ opt.optionName }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>
          <span v-else>/</span>
        </span>
        <span slot="taxAmount" slot-scope="text, record">
          <a-form-model-item>
            {{ record.taxAmount }}
          </a-form-model-item>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a @click="deleteBIll(index)">删除</a>
        </span>
      </xz-table>
    </a-form-model>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'BillInfoTable',
  components: {
    xzTable,
  },
  props: {
    provideBillData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      'measureUnitCache',
      'jifeiLeimuCache',
      'qualityAssTypeCache',
    ]),
  },
  watch: {
    provideBillData: {
      handler (v) {
        this.form.billList = [...v];
      },
      immediate: true,
    },
    measureUnitCache: {
      handler (v) {
        const defaultUnit = v.filter(v => v.defaultFlag);

        this.defaultUnit = defaultUnit?.[0]?.optionName;
      },
      immediate: true,
    },
  },
  data () {
    const validateTaxPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('请输入'));
      } else if (value === 0) {
        callback(new Error('含税单价不能为0'));
      }

      callback();
    };

    return {
      form: {
        billList: [],
      },
      quotationItems: [
        {
          name: '人工',
          value: '1',
        },
        {
          name: '备件',
          value: '2',
        },
      ],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '计费类目',
          dataIndex: 'type',
          key: 'type',
          width: 100,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '计费项名称',
          dataIndex: 'name',
          key: 'name',
          width: 180,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '零备件号',
          dataIndex: 'deviceRegisterCode',
          key: 'deviceRegisterCode',
          width: 180,
          scopedSlots: { customRender: 'deviceRegisterCode' },
        },
        {
          title: '含税单价（元）',
          dataIndex: 'taxPrice',
          key: 'taxPrice',
          scopedSlots: { customRender: 'taxPrice' },
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: 100,
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '质保期/保修期',
          dataIndex: 'warrantyPeriod',
          key: 'warrantyPeriod',
          scopedSlots: { customRender: 'warrantyPeriod' },
        },
        {
          title: '含税金额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 100,
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        taxPrice: [
          { required: true, validator: validateTaxPrice, trigger: 'blur' },
        ],
        quantity: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        unit: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        warrantyPeriod: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      defaultUnit: '',
    };
  },
  methods: {
    newBill () { // 收款计划
      this.form.billList.push({
        type: '1',
        name: undefined,
        deviceRegisterCode: undefined,
        taxPrice: undefined,
        quantity: 1,
        unit: '项',
        warrantyPeriod: undefined,
        warrantyPeriodUnit: undefined,
        taxAmount: undefined,
      });
    },
    deleteBIll (index) {
      this.form.billList.splice(index, 1);
    },
    handleChange (v, record) {
      if (v === '1') { // 人工
        record.name = undefined;
        record.deviceRegisterCode = undefined;
        record.taxPrice = undefined;
        record.quantity = 1;
        record.unit = '项';
        record.warrantyPeriod = undefined;
        record.warrantyPeriodUnit = undefined;
        record.taxAmount = undefined;
      } else { // 备件
        record.name = undefined;
        record.deviceRegisterCode = undefined;
        record.taxPrice = undefined;
        record.quantity = undefined;
        record.unit = this.defaultUnit;
        record.warrantyPeriod = undefined;
        record.warrantyPeriodUnit = '月';
        record.taxAmount = undefined;
      }
    },
    handleTotalMount (record) {
      record.taxAmount = record.taxPrice && record.quantity ? record.taxPrice * record.quantity : undefined;
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.billInfoForm.validate(async valid => {
          if (valid) {
            this.$emit('update:provideBillData', this.form.billList);
            resolve(valid);
          } else {
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () { },
};
</script>

<style scoped lang="scss">
.bill_info_table {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  /deep/ .ant-form-item {
    margin-bottom: 12px;

    .ant-form-explain {
      display: none;
    }
  }

  /deep/ .ant-table-tbody>tr>td {
    padding: 8px 16px 0;
    overflow-wrap: break-word;
  }

  &.text {
    display: flex;
    align-items: center;

    span {
      width: 5em;
    }

    .ant-input {
      flex: 1;
    }
  }
}
</style>

