<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">保养服务合同</div>
      <table border="1" class="dashed">
        <tr>
          <td colspan="2">甲方（服务接收方）：</td>
          <td colspan="3">{{ contractData.partA.name }}</td>
          <td colspan="3">最终用户：{{ contractData.partA.finalCustomerName || '' }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partA.address }}</td>
          <td colspan="3">邮编：{{ contractData.partA.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partA.telephone }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partA.fax }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="2">乙方（服务提供方）：</td>
          <td colspan="6">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partB.address || '' }}</td>
          <td colspan="3">邮编：{{ contractData.partB.postCode }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partB.telephone || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partB.fax || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </table>
      <div class="gap-top-2em indent-2em">甲乙双方经过平等协商，达成本保养服务合同（以下简称“合同”），合同内容包括具体条款、附件及合同中提及的其他文件。</div>
      <div class="gap-top-2em title2">1.&emsp;合同有效期：</div>
      <div class="indent-2em">自{{ contractData.effectiveDate[0] }}年{{ contractData.effectiveDate[1] }}月{{
        contractData.effectiveDate[2] }}日 至
        {{ contractData.terminalDate[0] }}年{{ contractData.terminalDate[1] }}月{{ contractData.terminalDate[2] }}日止。</div>
      <div class="gap-top-2em title2">2.&emsp;保养服务范围：</div>
      <div class="indent-2em">2.1&ensp;设备类型：</div>
      <table border="1" class="whole-node">
        <thead>
          <tr>
            <th style="width: 50px;">序号</th>
            <th>设备名称</th>
            <th>设备型号</th>
            <th>设备序列号</th>
            <th>保养开始日期</th>
            <th>保养终止日期</th>
            <th style="width: 80px">保养次数</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in contractData.deviceList" :key="`device${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ d.genericTerm }}</td>
            <td>{{ d.deviceModel }}</td>
            <td>{{ d.deviceSerialNumber }}</td>
            <td>{{ d.startServiceTerm }}</td>
            <td>{{ d.endServiceTerm }}</td>
            <td>{{ d.maintenanceTimes }}</td>
          </tr>
        </tbody>
      </table>
      <div class="gap-top-1em indent-2em">2.2&ensp;附加设备系统/服务：{{ contractData.additionalDeviceSystemServe }}</div>
      <div class="gap-top-1em indent-2em">乙方仅对以上列明的设备及附加设备系统/服务（以下简称“设备”）予以保养。</div>
      <div class="gap-top-2em title2">3.&emsp;服务类别：</div>
      <div class="indent-2em">乙方提供的合同服务类别为：保养服务合同</div>
      <div class="indent-2em">保养时间：乙方在预期保养时间之前一周内与甲方联系并约定保养时间</div>
      <div class="gap-top-2em title2">4.&emsp;甲方的责任：</div>
      <div class="indent-2em">
        <span style="position: absolute;">4.1&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          甲方应按设备操作手册的要求进行操作及日常维护，保证设备的电气环境（电源质量、接地、温湿度、电磁干扰、腐蚀性气体等）符合设备安装手册中的要求,保证设备所需电源,水源、有活性的辐射源（适用于PET/CT等核医学设备）的正常供应及开启和关闭。由于甲方原因造成设备不能正常运转或无法提供保养，乙方不承担保养责任。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute;">4.2&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">对于乙方在提供服务期间所提供的信息,甲方应该保密。乙方对该信息拥有所有权，未经乙方书面同意，甲方不得擅自使用或泄露给外界。
        </div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.3&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">甲方应按合同约定的方式按时向乙方支付保养服务费。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.4&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          甲方应保证设备在乙方提供保养服务前为原厂配置并处于良好状态，若甲方隐瞒其故障或改用非原厂认证配置，所造成的损失由甲方自行承担，乙方有权解除合同。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">4.5&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          甲方需向乙方提供或协助乙方要求医疗器械生产经营企业提供相关设备的维护手册、维修手册、软件备份、故障代码表、备件清单、零部件、维修密码等维护维修必需的材料和信息。</div>
      </div>
      <div class="gap-top-2em title2">5.&emsp;乙方的责任：</div>
      <div class="indent-2em">
        <span style="position: absolute;">5.1&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          定期保养：乙方每年提供定期维护次数以双方约定为准。在预期保养时间之前一周内与甲方联系并约定保养时间，如因甲方原因导致不能执行保养，乙方将不对此承担责任。计划性定期的维护包括设备清洁、性能测试及校准、必要的机械或电气的检查，以及非紧急性质的预防性维护，和确保系统能按照制造商的产品规格运行的其它维护。此项定期维护服务间隔进行。
        </div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">5.2&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">在合同有效期内，本合同服务范围内的设备进行约定保养服务所发生的人工和乙方差旅费用由乙方承担。</div>
      </div>
      <div class="gap-top-2em title2">6.&emsp;合同总价款：</div>
      <div class="indent-2em">
        单价（人民币）:{{ contractData.totalAmountPerYear }}元/年&emsp;(大写): {{ contractData.chineseTotalAmountPerYear }}。
      </div>
      <div class="indent-2em">
        总价（人民币）:{{ contractData.totalAmount }}元&emsp;&ensp;&emsp;(大写): {{ contractData.chineseTotalAmount }}。
      </div>
      <div class="indent-2em">
        以上价款为含税，税率为{{ contractData.taxRate }}，如因国家相关规定导致税率产生变化，则以付款时适用的税率为准。
      </div>
      <div class="indent-2em">
        对于合同项目下涉及的零备件，如无特殊说明甲方需在更换后将原备件退还给乙方；未能归还的，甲方应当额外向乙方支付该备件采购金额的 30 % 。
      </div>
      <div class="gap-top-2em title2">7.&emsp;付款方式：</div>
      <div class="indent-2em">7.1&ensp;经双方协商，采取以下方式付款：</div>
      <div v-if="contractData.paymentList.length === 1" class="indent-2em">合同签署后{{ contractData.paymentDays
      }}日内一次性支付全部价款。甲方应当按照合同约定将合同款项支付至交付乙方指定账户。</div>
      <template v-else>
        <div class="indent-2em">甲方按照如下约定分{{ contractData.paymentList.length || '' }}期向乙方支付合同款项：</div>
        <div v-for="(payment, index) in contractData.paymentList" class="indent-2em" :key="index">
          第{{ payment.index }}期：在{{ payment.paymentDateArray[0] }}年{{ payment.paymentDateArray[1] }}月{{
            payment.paymentDateArray[2] }}日前支付人民币{{ payment.paymentAmount || '' }}元；</div>
      </template>
      <div class="indent-2em">7.2&ensp;乙方指定账户信息如下：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="indent-2em">7.3&ensp;按照如下方式开具发票：</div>
      <div class="indent-2em">乙方每收到甲方支付的一笔款项后向甲方开具合规有效的等值发票（发票类型为：{{ contractData.invoiceTypeName }} ）。</div>
      <div class="indent-2em">甲方在收到乙方开具的合规有效的等值发票后（发票类型为：{{ contractData.invoiceTypeName }} ）按照双方约定向乙方支付货款。</div>
      <div class="gap-top-2em title2">8.&emsp;豁免及限制：</div>
      <div class="indent-2em"><span style="position: absolute;">8.1&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">本合同不包括维修、设备拆机、翻新、重装、迁移、搬动、保险费用等有偿服务项目（甲方如果要求乙方提供服务，则另行计费）。
        </div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">8.2&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">本设备配置的外周设备，如工作站（思创、DELL、杏翔、Sun、思越星、Super Micro
          Compure采集工作站、青兰、Radworks、
          DragonView、Terra、惠普等)
          、激光相机、洗片机、高压注射器、病人监护仪、定位仪、稳压器等等，经双方协商，乙方将提供有偿维修服务。
        </div>
      </div>
      <div class="gap-top-2em title2">9.&emsp;信息保密：</div>
      <div class="indent-2em">甲乙双方对本合同及本合同相关的其它信息、涉及乙方知识产权的相关信息均负有保密义务。未经另一方事先书面同意，任何一方不得将该信息的任何部分向任何第三方或社会公众披露。</div>
      <div class="gap-top-2em title2">10.&emsp;违约责任：</div>
      <div class="indent-2em">
        <span style="position: absolute;">10.1&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          由于公认的不可抗力事件造成的损坏（如自然灾害、爆炸、房屋倒塌、暴乱、坠机及蓄意破坏、缺乏燃料或水电、劳资纠纷、罢工、战争等），乙方根据实际情况部分或全部免除责任，并及时通知甲方。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute;">10.2&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          因为乙方无故不履行合同或者履行合同不符合约定给甲方造成损失的，乙方负责赔偿直接损失，该损失赔偿额以该设备当期合同价款为限。但因不可抗力或乙方不可能控制的因素导致的情形除外。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">10.3&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">在执行合同期间，甲方由于非乙方的原因而不再履行本合同，除付清至终止合同日期前应付的款项，并需向乙方承担未付款项 25
          %的违约金。如果甲方因为合理原因需要解除或变更合同的,必须以书面形式提前向乙方提出申请,经双方协商确认后,合同解除或变更生效。对生效日之前乙方已提供的服务,甲方仍有义务支付相应款项。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">10.4&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">在合同有效期内，未经乙方认可甲方擅自维修，移机，保养及更换第三方零备件及耗材，乙方有权解除合同。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute;">10.5&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          如果甲方超出本合同付款条款规定期限30天未支付相关款项，乙方有权中止或解除合同，并可要求甲方支付所有中止或解除日前已到期的应付款、10.3条约定的违约金及因维权产生的其他费用（包括但不限于诉讼费、合理的律师费等）。在中止或解除合同之前，乙方将书面通知甲方。
        </div>
      </div>
      <div class="gap-top-2em title2">11.&emsp;反腐败条款：</div>
      <div class="indent-2em">
        <span style="position: absolute;">11.1&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守各项规定。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">11.2&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。</div>
      </div>
      <div class="indent-2em"><span style="position: absolute;">11.3&ensp;</span>
        <div style="margin-left: 2em;display: inline-block;">
          若由于任何一方违反本协议关于反商业贿赂的约定而给对方造成任何损失，包括但不限于商誉损失、政府调查、罚款等，该方应对守约方承担赔偿责任。</div>
      </div>
      <div class="gap-top-2em title2">12.&emsp;争议解决及法律适用：</div>
      <div class="indent-2em">双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。</div>
      <div class="gap-top-2em title2">13.&emsp;其他：</div>
      <div class="indent-2em">
        本合同经双方签字盖章生效，一式___份，甲方执___份，乙方执___份，双方所执签字盖章合同具有同等法律效力。</div>
      <div class="indent-2em">合同附件清单：（可删除没有的部分）。</div>
      <div v-if="!contractData.attachmentList.length" class="indent-2em">无</div>
      <div v-else v-for="(annex, index) in contractData.attachmentList" class="indent-2em" :key="`attach${index}`">{{ `${index +
        1}、${annex.businessTypeDesc || '/'}` }}</div>
      <div class="indent-2em">
        本合同的附件是合同的有机组成部分，具有补充、解释或修改合同的作用，对双方有约束力。
      </div>
      <table border="1" class="gap-top-2em dashed whole-node">
        <tr height="150">
          <td class="title2">甲&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
          <td class="title2">乙&emsp;&emsp;方：<br />（盖章）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
          <td class="title2">授权代表：<br />（签字）</td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center;">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MaintenanceContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  methods: {
    handlePrint () {
      const style = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {padding: 8px;word-break: break-all;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: .5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      padding: 8px;
      word-break: break-all;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}</style>
