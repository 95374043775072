<template>
    <div class="hosipital_asset_related_devices">
      <div class="title gap-12 "><span v-if="required" style="color: #f5222d; margin-right: 4px;">*</span>履约标的<a-alert
          v-if="required && showWarning" message="履约标的不能为空" banner closable @close="showWarning = false" /></div>
      <a-form-model ref="relatedDevices" :model="form" :rules="rules">
        <xz-table ref="table" :columns="deviceColumns" :data="form.deviceList" :enableRowSelection="!noAction"
          :scroll="{ x: noAction ? 0 : 1300, y: 600 }" :aSlotsKey="['customTitle']" @change="handleSelectChange">
          <template #buttons>
            <div v-if="!noAction">
              <a-button v-if="!['QUOTATION_LETTER'].includes(templateType)" type="primary" icon="plus" @click="newDevice">
                新增行
              </a-button>
              <!--<a-button v-if="!['SINGLE_REPAIR'].includes(businessType)" type="primary" icon="plus" @click="batchAddDevice">
                批量录入
              </a-button>-->
              <a-popconfirm v-if="!['QUOTATION_LETTER'].includes(templateType)" placement="leftTop" ok-text="确认"
                cancel-text="取消" @confirm="batchDeleteDevice">
                <template slot="title">
                  {{ !toDeleteKeys.length ? '请选择需要删除的数据' : '确定删除当前数据？删除后数据将无法恢复' }}
                </template>
                <a-button>
                  批量删除
                </a-button>
              </a-popconfirm>
              <a-popconfirm v-if="!['QUOTATION_LETTER'].includes(templateType)" placement="leftTop" ok-text="确认"
                cancel-text="取消" @confirm="emptyDevice">
                <template slot="title">
                  确定清空当前数据？清空后数据将无法恢复
                </template>
                <a-button>
                  清空
                </a-button>
              </a-popconfirm>
              <!--<a-button v-if="!['QUOTATION_LETTER'].includes(templateType)" @click="importByExcel">
                表格导入
              </a-button>-->
              <a-button v-if="!['QUOTATION_LETTER'].includes(templateType)" @click="importWithAssets">
                资产导入
              </a-button>
            </div>
          </template>
          <span slot="customTitle">关联设备
            <a-tooltip placement="topRight">
              <template slot="title">
                <span style="font-size: 10px;">设备名称_设备型号_设备品牌_厂家编码/设备序列号</span>
              </template><a-icon style="margin-left: 6px;" type="question-circle" theme="filled" />
            </a-tooltip>
          </span>
          <span slot="genericTerm" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.genericTerm'" :rules="rules.genericTerm">
              <div style="display: flex; align-items: center;">
                <a-input :disabled="record.deviceSource === 'XZPT' || record.deviceSource === 'XZERP'"
                  v-model="record.genericTerm" placeholder="请点击" @click="handleDeviceInputClick(record)">
                </a-input>
                <a-icon type="plus-circle" style="color: rgba(0,0,0,.45); margin-left: 6px;"
                  @click.stop="showDeviceModal(record)" />
              </div>
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="faultDescription" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.faultDescription'"
              :rules="rules.faultDescription">
              <a-input v-model="record.faultDescription" valueFormat="x" />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="installationDate" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.installationDate'"
              :rules="rules.installationDate">
              <a-date-picker v-model="record.installationDate" valueFormat="x" style="width: 100%;" allow-clear>
              </a-date-picker>
            </a-form-model-item>
            <span v-else>
              {{ formatDate(text) }}
            </span>
          </span>
          <span slot="serviceTerm" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.serviceTerm'" :rules="rules.serviceTerm">
              <a-range-picker v-model="record.serviceTerm" valueFormat="x" style="width: 100%;" allow-clear>
              </a-range-picker>
            </a-form-model-item>
            <span v-else>
              {{ formatDate(record.serviceTerm[0]) }} ~ {{
                formatDate(record.serviceTerm[1]) }}
            </span>
          </span>
          <span slot="maintenanceTimes" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.maintenanceTimes'"
              :rules="rules.maintenanceTimes">
              <a-input-number v-model="record.maintenanceTimes" placeholder="请输入" :min="0" :step="1" style="width: 100%;" />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="taxRate" slot-scope="text, record, index">
            <a-form-model-item :prop="'deviceList.' + index + '.taxRate'" :rules="rules.taxRate">
              <a-select v-model="record.taxRate" placeholder="请选择" allowClear style="width: 100%;" :disabled="!!taxRate"
                @change="handleTaxAmountChange">
                <a-select-option v-for="opt in taxRateCache" :key="opt.optionName" :value="opt.optionName">
                  {{ opt.optionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </span>
          <span slot="taxAmount" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'deviceList.' + index + '.taxAmount'" :rules="rules.taxAmount">
              <a-input-number v-model="record.taxAmount"
                :placeholder="businessType !== 'SINGLE_REPAIR' ? '请输入' : '根据报价单明细金额自动计算'" :min="0" :step="0.01"
                style="width: 100%;" :precision="2" :disabled="businessType === 'SINGLE_REPAIR'"
                @change="handleTaxAmountChange" />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="action" slot-scope="text, record, index">
            <a v-if="['SINGLE_REPAIR'].includes(businessType)" :class="[record.genericTerm ? '' : 'disabledA']"
              @click="handleQuote(record)">报价单明细
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>请先选择关联设备</span>
                </template>
                <a-icon v-if="!record.genericTerm" type="info-circle" theme="filled" />
              </a-tooltip>
            </a>
            <a-divider v-if="['SINGLE_REPAIR'].includes(businessType)" type="vertical" />
            <a-popconfirm v-if="['MAINTENANCE', 'SINGLE_REPAIR', 'WARRANTY', 'TECHNOLOGY_SERVICE'].includes(templateType)" placement="leftTop"
              ok-text="确认" cancel-text="取消" @confirm="deleteDevice(index)">
              <template slot="title">
                确定删除当前数据？删除后数据将无法恢复
              </template>
              <a>删除</a>
            </a-popconfirm>
          </span>
          <template slot="footer" slot-scope="currentPageData">
            <div>
              <b>含税总金额（元）：</b>{{ parseInt(Math.round(currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0) * 100)) / 100 || '' }}
            </div>
          </template>
        </xz-table>
      </a-form-model>
      <div v-if="['MAINTENANCE', 'SINGLE_REPAIR', 'WARRANTY'].includes(templateType)" class="extra-input">
        <span class="title">附加设备系统/服务</span>
        <a-input v-if="!noAction" v-model="additionalDeviceSystemServe"></a-input>
        <div v-else>{{ additionalDeviceSystemServe }}</div>
      </div>
      <a-modal v-model="visible" :title="setTitle()" :width="isRelatedDevice ? 500 : 1300"
        :bodyStyle="{ padding: '0 12px' }" centered :maskClosable="false" :destroyOnClose="true">
        <devices v-if="isDevice" ref="devices" @handleData="handleDeviceData" :selectType="selectType"
          :selectedIds="selectedIds" :assetSelectedRows="assetSelectedRows" :libSelectedRows="libSelectedRows"></devices>
        <BillInfoTable v-if="isBill" ref="billInfoTable" :provideBillData.sync="currentRecord.billList"></BillInfoTable>
        <a-form-model v-if="isRelatedDevice" :model="deviceForm" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="设备名称">
            <a-input v-model="deviceForm.deviceName" />
          </a-form-model-item>
          <a-form-model-item label="设备型号">
            <a-input v-model="deviceForm.deviceModel" />
          </a-form-model-item>
          <a-form-model-item label="设备品牌">
            <a-input v-model="deviceForm.deviceBrandName" />
          </a-form-model-item>
          <a-form-model-item label="厂家编码/设备序列号">
            <a-input v-model="deviceForm.deviceSerialNumber" />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <a-button @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary"
            :disabled="isRelatedDevice && (!deviceForm.deviceName && !deviceForm.deviceModel && !deviceForm.deviceBrandName && !deviceForm.deviceSerialNumber)"
            @click="handleOk">
            确认
          </a-button>
        </template>
      </a-modal>
    </div>
  </template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import devices from './Devices.vue';
import { mapGetters } from 'vuex';
import * as Moment from 'dayjs';
import BillInfoTable from './BillInfoTable.vue';
import _ from 'lodash';

const commonColumns = [
  {
    title: '序号',
    key: 'index',
    width: 80,
    ellipsis: true,
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    dataIndex: 'genericTerm',
    key: 'genericTerm',
    slots: { title: 'customTitle' },
    ellipsis: true,
    scopedSlots: { customRender: 'genericTerm' },
  },
];

const maintenanceColumns = [
  {
    title: '服务期限',
    dataIndex: 'serviceTerm',
    key: 'serviceTerm',
    ellipsis: true,
    scopedSlots: { customRender: 'serviceTerm' },
  },
  {
    title: '保养次数',
    dataIndex: 'maintenanceTimes',
    key: 'maintenanceTimes',
    ellipsis: true,
    scopedSlots: { customRender: 'maintenanceTimes' },
  },
];

const singleRepairColumns = [
  {
    title: '故障描述',
    dataIndex: 'faultDescription',
    key: 'faultDescription',
    ellipsis: true,
    scopedSlots: { customRender: 'faultDescription' },
  },
];

const warrantyColumns = [
  {
    title: '装机日期',
    dataIndex: 'installationDate',
    key: 'installationDate',
    ellipsis: true,
    scopedSlots: { customRender: 'installationDate' },
  },
  {
    title: '服务期限',
    dataIndex: 'serviceTerm',
    key: 'serviceTerm',
    ellipsis: true,
    scopedSlots: { customRender: 'serviceTerm' },
  },
  {
    title: '保养次数',
    dataIndex: 'maintenanceTimes',
    key: 'maintenanceTimes',
    ellipsis: true,
    scopedSlots: { customRender: 'maintenanceTimes' },
  },
];

const tailColumns = [
  {
    title: '税率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    ellipsis: true,
    scopedSlots: { customRender: 'taxRate' },
  },
  {
    title: '含税金额（元）',
    dataIndex: 'taxAmount',
    key: 'taxAmount',
    ellipsis: true,
    scopedSlots: { customRender: 'taxAmount' },
  },
];

const columns = {
  MAINTENANCE: [...commonColumns, ...maintenanceColumns, ...tailColumns],
  WARRANTY: [...commonColumns, ...warrantyColumns, ...tailColumns],
  SINGLE_REPAIR: [...commonColumns, ...singleRepairColumns, ...tailColumns],
  TECHNOLOGY_SERVICE: [...commonColumns, ...tailColumns],
};

export default {
  name: 'RelatedDevices',
  components: {
    xzTable,
    devices,
    BillInfoTable,
  },
  props: {
    businessType: {
      type: String,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    provideDeviceList: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: true,
    },
    provideBillList: {
      type: Array,
      default: () => [],
    },
    taxRate: {
      type: String,
    },
    providedAdditionalDeviceSystemServe: {
      type: String,
      default: undefined,
    },
    defaultServiceTerm: {
      type: [Array, undefined],
      default: undefined,
    },
  },
  computed: {
    ...mapGetters([
      'taxRateCache',
      'supplierCache',
    ]),
    deviceColumns () {
      return this.noAction ? columns[this.businessType] : [
        ...columns[this.businessType],
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ];
    },
  },
  watch: {
    provideDeviceList: {
      handler (v) {
        this.form.deviceList = [...v];
      },
      immediate: true,
    },
    providedAdditionalDeviceSystemServe: {
      handler (v) {
        this.additionalDeviceSystemServe = v;
      },
      immediate: true,
    },
  },
  data () {
    return {
      showWarning: false,
      isDevice: false,
      isBill: false,
      isRelatedDevice: false,
      isImportByExcel: false,
      visible: false,
      additionalDeviceSystemServe: undefined,
      form: {
        deviceList: [],
      },
      currentRecord: {},
      rules: {
        genericTerm: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        faultDescription: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        installationDate: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        serviceTerm: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        maintenanceTimes: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        taxRate: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        taxAmount: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      selectType: 'radio',
      selectedIds: [],
      assetSelectedRows: [],
      libSelectedRows: [],
      toDeleteKeys: [],
      deviceForm: null,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  },
  methods: {
    handleQuote (record) {
      if (!record.genericTerm) {
        return false;
      }

      this.isDevice = false;
      this.isBill = true;
      this.isRelatedDevice = false;

      this.currentRecord = record;

      this.visible = true;
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    handleSelectChange (keys) {
      this.toDeleteKeys = keys;
    },
    back () {
      this.$router.back();
    },
    newDevice () {
      this.form.deviceList.push({
        genericTerm: undefined,
        assetNumber: undefined,
        deviceCode: undefined,
        deviceBrandId: undefined,
        deviceBrandName: undefined,
        deviceCategoryId: undefined,
        deviceCategoryName: undefined,
        deviceEnterpriseId: undefined,
        deviceEnterpriseName: undefined,
        deviceId: undefined,
        deviceModel: undefined,
        deviceName: undefined,
        deviceRegisterCode: undefined,
        deviceSerialNumber: undefined,
        deviceSource: undefined,
        faultDescription: undefined,
        installationDate: undefined,
        maintenanceTimes: undefined,
        manufacturerCode: undefined,
        remark: undefined,
        serviceTerm: this.defaultServiceTerm,
        taxAmount: undefined,
        taxRate: this.taxRate,
        billList: [],
        uniqueValue: _.uniqueId(),
      });
    },
    handleDeviceInputClick (record) {
      if (record.deviceSource === 'XZPT' || record.deviceSource === 'XZERP') {
        return false;
      }

      this.isDevice = false;
      this.isBill = false;
      this.isRelatedDevice = true;

      this.currentRecord = record;

      this.deviceForm = _.cloneDeep(record);

      this.visible = true;
    },
    showDeviceModal (record) {
      this.currentRecord = record;

      this.isDevice = true;
      this.isBill = false;
      this.isRelatedDevice = false;

      this.assetSelectedRows = [];
      this.libSelectedRows = [];
      this.selectedIds = [];

      this.form.deviceList.forEach(v => {
        if (v.deviceId) {
          this.selectedIds.push(v.deviceId);
        }
      });
      this.selectType = 'radio';
      this.visible = true;
    },
    batchAddDevice () { },
    handleTaxAmountChange (isGeneralInvoice = false) {
      this.$emit('handleTotalAmount', this.form.deviceList, isGeneralInvoice);
    },
    deleteDevice (index) {
      this.form.deviceList.splice(index, 1);

      if (this.businessType === 'SINGLE_REPAIR') {
        this.form.deviceList.map((device, pIndex) => {
          device.billList.forEach((bill, cIndex) => {
            bill.index = `${pIndex + 1}-${cIndex + 1}`;
          });
        });

        this.$emit('update:provideBillList', this.form.deviceList.map(v => v.billList).flat());
      }

      this.handleTaxAmountChange();
    },
    batchDeleteDevice () {
      if (!this.toDeleteKeys.length) {
        return false;
      }

      for (let i = 0; i < this.form.deviceList.length;) {
        if (this.toDeleteKeys.includes(i)) {
          this.form.deviceList.splice(i, 1);
        } else {
          i++;
        }
      }

      if (this.businessType === 'SINGLE_REPAIR') {
        this.form.deviceList.map((device, pIndex) => {
          device.billList.forEach((bill, cIndex) => {
            bill.index = `${pIndex + 1}-${cIndex + 1}`;
          });
        });

        this.$emit('update:provideBillList', this.form.deviceList.map(v => v.billList).flat());
      }

      this.handleTaxAmountChange();
    },
    emptyDevice () {
      this.form.deviceList = [];

      if (this.businessType === 'SINGLE_REPAIR') {
        this.$emit('update:provideBillList', []);
      }

      this.handleTaxAmountChange();
    },
    // 表格导入
    importByExcel () {
      this.isDevice = false;
      this.isBill = false;
      this.isRelatedDevice = false;
      this.isImportByExcel = true;
      this.visible = true;
    },
    importWithAssets () {
      this.assetSelectedRows = [];
      this.libSelectedRows = [];
      this.selectedIds = [];

      this.form.deviceList.forEach(v => {
        if (v.deviceId) {
          this.selectedIds.push(v.deviceId);

          if (v.deviceSource === 'XZPT') {
            this.assetSelectedRows.push(v);
          } else if (v.deviceSource === 'XZERP') {
            this.libSelectedRows.push(v);
          }
        }
      });
      this.selectType = 'checkbox';

      this.isDevice = true;
      this.isBill = false;
      this.isRelatedDevice = false;

      this.visible = true;
    },
    handleCancel () {
      this.visible = false;
    },
    setTitle () {
      let ret = '';

      if (this.isDevice) {
        ret = '选择设备';
      } else if (this.isBill) {
        ret = '计费清单';
      } else if (this.isRelatedDevice) {
        ret = '关联设备信息';
      }

      return ret;
    },
    async handleOk () {
      if (this.isDevice) {
        this.$refs.devices.handleConfirm();
      } else if (this.isBill) {
        await this.$refs.billInfoTable.validate();

        this.currentRecord.taxAmount = this.currentRecord.billList.reduce((pre, cur) => {
          return pre + cur.taxAmount;
        }, 0);

        this.form.deviceList.map((device, pIndex) => {
          device.billList.forEach((bill, cIndex) => {
            bill.index = `${pIndex + 1}-${cIndex + 1}`;
          });
        });

        this.$emit('update:provideBillList', this.form.deviceList.map(v => {
          v.billList.forEach(bill => {
            bill.taxRate = v.taxRate;
          });
          return v.billList;
        }).flat());

        this.handleTaxAmountChange();
      } else if (this.isRelatedDevice) {
        this.currentRecord.deviceName = this.deviceForm.deviceName;
        this.currentRecord.deviceModel = this.deviceForm.deviceModel;
        this.currentRecord.deviceBrandName = this.deviceForm.deviceBrandName;
        this.currentRecord.deviceSerialNumber = this.deviceForm.deviceSerialNumber;
        this.currentRecord.deviceSource = 'MANUAL_ADD';

        this.currentRecord.genericTerm = `${this.deviceForm.deviceName || '-'}/${this.deviceForm.deviceModel || '-'}/${this.deviceForm.deviceBrandName || '-'}/${this.deviceForm.deviceSerialNumber || '-'}`;
      }

      this.visible = false;
    },
    handleDeviceData (v) {
      if (this.selectType === 'checkbox') {
        const newSelectedIds = v.map(d => d.deviceId);

        const newDevices = v.filter(d => !this.selectedIds.includes(d.deviceId)); // 筛选出新勾选数据

        const toDeleteDeviceIds = this.selectedIds.filter(id => this.selectedIds.includes(id) && !newSelectedIds.includes(id));

        const devices = newDevices.map(device => ({
          genericTerm: `${device.deviceName || '-'}/${device.deviceModel || '-'}/${device.deviceBrandName || '-'}/${device.deviceSerialNumber || '-'}`,
          assetNumber: device.deviceCode || undefined,
          deviceCode: device.deviceCode,
          deviceBrandId: device.deviceBrandId,
          deviceBrandName: device.deviceBrandName,
          deviceCategoryId: device.deviceCategoryId,
          deviceCategoryName: device.deviceBrandName,
          deviceEnterpriseId: device.deviceEnterpriseId,
          deviceEnterpriseName: device.deviceEnterpriseName,
          deviceId: device.deviceId,
          deviceModel: device.deviceModel,
          deviceName: device.deviceName,
          deviceRegisterCode: device.deviceRegisterCode,
          deviceSerialNumber: device.deviceSerialNumber,
          deviceSource: device.deviceSource,
          faultDescription: device.faultDescription,
          installationDate: device.installationDate,
          maintenanceTimes: device.maintenanceTimes,
          manufacturerCode: device.manufacturerCode,
          remark: device.remark,
          serviceTerm: device.serviceTermStart && device.serviceTermEnd ? [device.serviceTermStart, device.serviceTermEnd] : this.defaultServiceTerm,
          taxAmount: device.taxAmount,
          taxRate: this.taxRate,
          billList: [],
          uniqueValue: _.uniqueId(),
        }));

        this.form.deviceList.forEach((v, index) => { // 删除先前存在本次未被勾选的数据
          if (toDeleteDeviceIds.includes(v.deviceId)) {
            this.form.deviceList.splice(index, 1);
          }
        });

        this.form.deviceList = [...this.form.deviceList, ...devices];
      } else {
        const device = v[0];

        this.currentRecord.genericTerm = `${device.deviceName || '-'}/${device.deviceModel || '-'}/${device.deviceBrandName || '-'}/${device.deviceSerialNumber || '-'}`;
        this.currentRecord.assetNumber = device.deviceCode || undefined;
        this.currentRecord.deviceCode = device.deviceCode;
        this.currentRecord.deviceBrandId = device.deviceBrandId;
        this.currentRecord.deviceBrandName = device.deviceBrandName;
        this.currentRecord.deviceCategoryId = device.deviceCategoryId;
        this.currentRecord.deviceCategoryName = device.deviceBrandName;
        this.currentRecord.deviceEnterpriseId = device.deviceEnterpriseId;
        this.currentRecord.deviceEnterpriseName = device.deviceEnterpriseName;
        this.currentRecord.deviceId = device.deviceId;
        this.currentRecord.deviceModel = device.deviceModel;
        this.currentRecord.deviceName = device.deviceName;
        this.currentRecord.deviceRegisterCode = device.deviceRegisterCode;
        this.currentRecord.deviceSerialNumber = device.deviceSerialNumber;
        this.currentRecord.deviceSource = device.deviceSource;
        this.currentRecord.faultDescription = device.faultDescription;
        this.currentRecord.installationDate = device.installationDate;
        this.currentRecord.maintenanceTimes = device.maintenanceTimes;
        this.currentRecord.manufacturerCode = device.manufacturerCode;
        this.currentRecord.remark = device.remark;
        this.currentRecord.serviceTerm = device.serviceTermStart && device.serviceTermEnd ? [device.serviceTermStart, device.serviceTermEnd] : this.currentRecord.serviceTerm;
        this.currentRecord.taxAmount = device.taxAmount;
        this.currentRecord.taxRate = this.taxRate;
        this.currentRecord.billList = [];
      }
    },
    validate () {
      return new Promise((resolve, reject) => {
        if (!this.form.deviceList.length) {
          this.showWarning = true;

          reject(new Error('fail'));
        } else {
          this.$refs.relatedDevices.validate(async valid => {
            if (valid) {
              this.$emit('update:provideDeviceList', this.form.deviceList);
              this.$emit('update:providedAdditionalDeviceSystemServe', this.additionalDeviceSystemServe);
              resolve(valid);
            } else {
              reject(new Error('fail'));
            }
          });
        }
      });
    },
  },
};
</script>

  <style scoped lang="scss">
  .gap-12 {
    margin-bottom: 12px;
  }

  .hosipital_asset_hosipital_asset_related_devices {
    background-color: #fff;
    padding: 12px;
    margin-bottom: 6px;

    .title {
      font-weight: bold;
      display: flex;
      align-items: center;

      .ant-alert {
        flex: 1;
        margin-left: 12px;
      }
    }

    /deep/ .ant-form-item {
      margin-bottom: 12px;

      .ant-form-explain {
        display: none;
      }
    }

    /deep/ .ant-table-tbody>tr>td {
      padding: 8px 16px 0;
      overflow-wrap: break-word;
    }

    .extra-input {
      display: flex;
      align-items: flex-start;
      padding: 24px 0 12px 0;

      span {
        width: 9em;
        flex-shrink: 0;
      }

      .ant-input {
        flex: 1;
      }
    }

    .disabledA {
      color: gray;
      cursor: default;
    }
  }
  </style>

