<template>
  <div class="contract_base">
    <div class="title gap-12"><span v-if="required" style="color: #f5222d; margin-right: 4px;">*</span>合同基础信息</div>
    <a-form-model ref="saleContractForm" :model="form" :rules="rules" :wrapper-col="wrapperCol">
      <a-row v-if="templateType === 'WARRANTY'">
        <a-col :span="8">
          <a-form-model-item label="保修类型">
            <a-radio-group v-model="form.warrantyType" button-style="solid">
              <a-radio-button value="ALL">
                全保
              </a-radio-button>
              <a-radio-button value="ARTIFICIAL">
                人工保
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item ref="customerHead" label="客户抬头" prop="customerHead">
            <a-select show-search v-model="form.customerHead" placeholder="请输入关键字" :default-active-first-option="false"
              :show-arrow="false" :filter-option="false" @search="handleSearch" @change="handleCustomerChange">
              <template v-if="!customerList.length" slot="dropdownRender">
                <div style="padding: 20px 15px 40px;">
                  暂无数据，请点击<span @click="showHospitalDialog = true" style="color: #237FFA;cursor: pointer;">“新增”</span>按钮添加
                </div>
              </template>
              <a-select-option v-for="customer in customerList" :key="customer.id" :value="customer.name">
                {{ customer.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="accountingUnit" label="供应商抬头" prop="accountingUnit">
            <a-select v-model="form.accountingUnit" placeholder="请选择">
              <a-select-option v-for="opt in accountingOpts" :key="opt.value" :value="opt.accountName">
                {{ opt.accountName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="finalCustomerName" label="最终用户" prop="finalCustomerName">
            <a-select show-search v-model="form.finalCustomerName" placeholder="请输入关键字"
              :default-active-first-option="false" :show-arrow="false" :filter-option="false" not-found-content="暂无数据"
              @search="(v) => handleSearch(v, 'finalCustomerList')">
              <template v-if="!finalCustomerList.length" slot="dropdownRender">
                <div style="padding: 20px 15px 40px;">
                  暂无数据，请点击<span @click="showHospitalDialog = true" style="color: #237FFA;cursor: pointer;">“新增”</span>按钮添加
                </div>
              </template>
              <a-select-option v-for="customer in finalCustomerList" :key="customer.id" :value="customer.name">
                {{ customer.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="发票类型" prop="invoiceType">
            <a-select v-model="form.invoiceType" placeholder="请选择">
              <a-select-option v-for="opt in InvoiceTypeEnum.filter(v => !v.value.includes('GENERAL'))" :key="opt.value" :value="opt.value">
                {{ opt.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="税率" prop="tariff">
            <a-select v-model="form.tariff" placeholder="请选择" @change="handleTaxrateChange"
              :disabled="businessType === 'MAINTENANCE'" allow-clear>
              <a-select-option v-for="opt in taxRateCache" :key="opt.optionName" :value="opt.optionName">
                {{ opt.optionName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="totalAmount" label="合同金额" prop="totalAmount">
            <a-input-number v-model="form.totalAmount" :min="0" :step="0.01" :precision="2" placeholder="根据履约标的自动计算"
              style="width: 100%;" disabled />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="生效起止日" required prop="saleContractDate">
            <a-range-picker v-model="form.saleContractDate" valueFormat="x" style="width: 100%;" @change="handleDateChange">
            </a-range-picker>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item ref="salesmanName" label="业务员" prop="salesmanName">
            <a-input v-model="form.salesmanName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="签订日期">
            <a-date-picker v-model="form.signedDate" style="width: 100%;" valueFormat="x" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="结算方式" prop="paymentMethod">
            <a-select v-model="form.paymentMethod" placeholder="请选择">
              <a-select-option v-for="opt in PaymentMethodEnum" :key="opt.value" :value="opt.value">
                {{ opt.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <a-form-model-item ref="bankAccounts" label="收款账号" prop="bankAccounts">
            <a-select v-model="form.bankAccounts" placeholder="请选择">
              <a-select-option v-for="opt in bankOpts" :key="opt.id" :value="`${opt.bankOfDeposit}_${opt.bankAccount}`">
                {{ `${opt.bankOfDeposit}_${opt.bankAccount}` }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16">
          <a-form-model-item ref="name" label="合同主题" prop="name">
            <a-input v-model="form.name" @blur="() => { $refs.name.onFieldBlur(); }"
              @change="() => { $refs.name.onFieldBlur(); }" placeholder="最终用户名称+业务类型名称+生效日期" allowClear />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <!-- 新增医院 -->
    <AddHospitalDialog :show="showHospitalDialog" @close="close" />
  </div>
</template>

<script>
import { InvoiceTypeEnum } from '@/enums/InvoiceTypeEnum';
import { PaymentMethodEnum } from '@/enums/PaymentMethodEnum';
import { mapGetters } from 'vuex';
import { getCustomer } from '@/api/contract';
import BusinessTypeModelMap from '@/enums/BusinessTypeEnum';
import AddHospitalDialog from '@/components/addHospitalDialog';
import * as Moment from 'dayjs';

export default {
  name: 'ContractBaseInfo',
  components: {
    AddHospitalDialog,
  },
  computed: {
    ...mapGetters([
      'taxRateCache',
      'supplierCache',
    ]),
    accountingOpts () {
      return this.supplierCache ? [this.supplierCache] : [];
    },
    bankOpts () {
      return this.supplierCache?.bankResDTOList || [];
    },
  },
  watch: {
    async customerId (v) {
      await this.handleSearch('', 'customerList');
      const customer = this.customerList.find(item => item.id === v);

      this.form.customerHead = customer.name;
      if (!this.form.finalCustomerName) {
        this.form.finalCustomerName = customer.name;
      }

      this.$emit('update:customer', customer);
    },
    'form.finalCustomerName': {
      handler () {
        if (!this.id && !this.form.name && this.form.finalCustomerName && this.form.saleContractDate) {
          this.form.name = `${this.form.finalCustomerName}_${BusinessTypeModelMap[this.businessType]}_${this.formatDate(this.form.saleContractDate[0], 'YYYYMMDD')}`;
        }
      },
    },
    'form.saleContractDate': {
      handler () {
        if (!this.id && !this.form.name && this.form.finalCustomerName && this.form.saleContractDate) {
          this.form.name = `${this.form.finalCustomerName}_${BusinessTypeModelMap[this.businessType]}_${this.formatDate(this.form.saleContractDate[0], 'YYYYMMDD')}`;
        }
      },
    },
    provideForm: {
      handler (v) {
        this.form = { ...v };
      },
      immediate: true,
      deep: true,
    },
    supplierCache: {
      handler (v) {
        if (!this.form.accountingUnit && v?.accountName) {
          this.form.accountingUnit = v.accountName;
        }
      },
      immediate: true,
    },
    bankOpts: {
      handler (v) {
        if (!this.form.bankAccounts && v?.[0]?.bankOfDeposit && v?.[0]?.bankAccount) {
          this.form.bankAccounts = `${v[0].bankOfDeposit}_${v[0].bankAccount}`;
        }
      },
      immediate: true,
    },
  },
  props: {
    customerId: {
      type: String,
      default: '',
    },
    customer: {
      type: Object,
      default: () => {},
    },
    businessType: {
      type: String,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    provideForm: {
      type: Object,
      default: () => ({
        customerHead: undefined,
        accountingUnit: undefined,
        finalCustomerName: undefined,
        invoiceType: undefined,
        tariff: undefined,
        totalAmount: undefined,
        saleContractDate: undefined,
        salesmanName: this.userInfo.name,
        signedDate: undefined,
        paymentMethod: undefined,
        bankAccounts: undefined,
        name: undefined,
        warrantyType: undefined,
      }),
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      BusinessTypeModelMap,
      finalCustomerList: [],
      customerList: [],
      InvoiceTypeEnum,
      PaymentMethodEnum,
      wrapperCol: { span: 16 },
      form: {
        customerHead: undefined,
        accountingUnit: undefined,
        finalCustomerName: undefined,
        invoiceType: undefined,
        tariff: undefined,
        totalAmount: undefined,
        saleContractDate: undefined,
        salesmanName: undefined,
        signedDate: undefined,
        paymentMethod: undefined,
        bankAccounts: undefined,
        name: undefined,
        warrantyType: undefined,
      },
      rules: {
        customerHead: [{ required: true, message: '请选择', trigger: 'change' }],
        accountingUnit: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        finalCustomerName: [
          { required: true, message: '请选择', trigger: ['change', 'blur'] },
        ],
        invoiceType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        totalAmount: [
          { required: true, message: '请完善履约标的含税金额', trigger: 'change' },
        ],
        saleContractDate: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        salesmanName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        paymentMethod: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        bankAccounts: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
        ],
      },
      showHospitalDialog: false,
    };
  },
  methods: {
    close () {
      this.showHospitalDialog = false;
      this.handleSearch('', 'customerList');
      this.handleSearch('', 'finalCustomerList');
    },
    async handleCustomerChange (v) {
      this.$local.remove('changeCustomer');
      const customer = this.customerList.find(customer => customer.name === v);

      if (!this.form.finalCustomerName) {
        this.form.finalCustomerName = v;
      }

      this.$emit('update:customer', customer);
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    async handleSearch (value, key = 'customerList') {
      this[key] = [];

      const { body } = await getCustomer({
        name: value,
        pageNum: 1,
        pageSize: 20,
      });

      this[key] = body.records || [];
    },
    handleDateChange () {
      this.$emit('update:provideForm', this.form);
    },
    handleTaxrateChange (v) {
      this.$emit('update:provideForm', this.form);
      this.$emit('updateRate', v);
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.saleContractForm.validate(async valid => {
          if (valid) {
            this.$emit('update:provideForm', this.form);
            resolve(valid);
          } else {
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () {
    this.handleSearch('', 'customerList');
    this.handleSearch('', 'finalCustomerList');
  },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.contract_base {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
  }

  /deep/ .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      float: left;
      width: 7em;
    }

    .ant-form-explain {
      font-size: 12px;
      height: auto;
    }
  }
}
</style>

