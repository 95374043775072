import Enum from '@/utils/Enum';

const BusinessTypeModelEnum = [
  {
    name: '保养',
    value: 'MAINTENANCE',
  },
  {
    name: '保修',
    value: 'WARRANTY',
  },
  /*
  {
    name: '移机装机',
    value: 'RELOCATION',
  },
  */
  {
    name: '维修服务',
    value: 'SINGLE_REPAIR',
  },
  {
    name: '技术服务',
    value: 'TECHNOLOGY_SERVICE',
  },
  /*
  {
    name: '资产管理',
    value: 'ASSET_MANAGEMENT',
  },
  */
  /*
  {
    name: '检测校准',
    value: 'DETECTION_CALIBRATION',
  },
  {
    name: '实物销售',
    value: 'ENTITY_SALE',
  },
  */
];

export default new Enum(BusinessTypeModelEnum);
export { BusinessTypeModelEnum };
