<template>
  <div class="maintenance_template_wrapper">
    <div class="content">
      <div v-show="step === 'first'" class="step">
        <ContractBaseInfo ref="contractBaseInfo" :provideForm.sync="saleContract.form" :businessType="businessType" :customer.sync="customer"  :customerId="customerId"
          :templateType="templateType" @updateRate="updateRelatedDevicesRate" />
        <CollectionPlanInfo ref="collectionPlanInfo" :businessType="businessType" :templateType="templateType"
          :providePaymentPlanList.sync="saleContract.paymentPlanList" :noAction="false" :max="collectionTotalAmount">
        </CollectionPlanInfo>
        <RelatedDevices ref="relatedDevices"
          :customerId="customer.id"
          :businessType="businessType" :templateType="templateType"
          :provideDeviceList.sync="saleContract.deviceList"
          :providedAdditionalDeviceSystemServe.sync="saleContract.additionalDeviceSystemServe" :noAction="false"
          :defaultServiceTerm="saleContract.form.saleContractDate"
          @handleCustomerId="handleCustomerId"
          @handleTotalAmount="handleTotalAmount" :taxRate="saleContract.form.tariff"></RelatedDevices>
        <AttachmentInfo ref="attachmentInfo" :provideAnnexList.sync="saleContract.annexList" :noAction="false">
        </AttachmentInfo>
        <div class="common text">
          <span class="title">合同备注</span>
          <a-input v-model="saleContract.remark" placeholder="请输入" allowClear style="width: 100%;"></a-input>
        </div>
      </div>
      <div v-show="step === 'second'" class="step">
        <div class="common">
          <div class="title gap-12">合同基础信息</div>
          <a-form-model ref="purchContractForm" :model="purchContract.form" :rules="purchContractRules"
            :wrapper-col="wrapperCol">
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="发票类型" prop="invoiceType">
                  <a-select v-model="purchContract.form.invoiceType" placeholder="请选择" allowClear @change="handleInvoiceChange">
                    <a-select-option v-for="opt in InvoiceTypeEnum" :key="opt.value" :value="opt.value">
                      {{ opt.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item ref="taxRate" label="税率" prop="taxRate">
                  <a-select v-model="purchContract.form.taxRate" placeholder="请选择" allow-clear
                    @change="updateRelatedPurchDevicesRate">
                    <a-select-option v-for="opt in taxRateCache" :key="opt.optionName" :value="opt.optionName">
                      {{ opt.optionName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item ref="totalAmount" label="合同金额" prop="totalAmount">
                  {{ purchContract.form.totalAmount }}
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="16">
                <a-form-model-item ref="bankAccounts" label="收款账号" prop="bankAccounts">
                  <a-select v-model="purchContract.form.bankAccounts" placeholder="请选择" allowClear>
                    <a-select-option v-for="opt in bankInfo" :key="opt.id"
                      :value="`${opt.bankOfDeposit}_${opt.bankAccount}`">
                      {{ `${opt.bankOfDeposit}_${opt.bankAccount}` }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-descriptions :style="{ padding: '0 16px' }">
            <a-descriptions-item label="客户抬头">
              {{ this.purchContract.form.accountingUnit }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商抬头">
              {{ this.purchContract.form.supName }}
            </a-descriptions-item>
            <a-descriptions-item label="最终用户">
              {{ this.purchContract.form.finalCustomerName }}
            </a-descriptions-item>
            <a-descriptions-item label="生效起止日">
              {{ formatDate(this.purchContract.form.effectiveDate) }} ~ {{
                formatDate(this.purchContract.form.contractDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="业务员">
              {{ this.purchContract.form.salesmanName }}
            </a-descriptions-item>
            <a-descriptions-item label="签订日期">
              {{ formatDate(this.purchContract.form.signedDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="结算方式">
              {{ PurchasePaymentMethodEnumMap[this.purchContract.form.payWay] }}
            </a-descriptions-item>
            <a-descriptions-item label="合同主题">
              {{ this.purchContract.form.name }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <CollectionPlanInfo ref="purchCollectionPlanInfo" :templateType="templateType"
          :providePaymentPlanList="purchContract.paymentPlanList" :noAction="true"></CollectionPlanInfo>
        <RelatedDevices ref="purchRelatedDevices" :businessType="businessType" :templateType="templateType"
          :provideDeviceList="purchContract.deviceList"
          :providedAdditionalDeviceSystemServe="purchContract.additionalDeviceSystemServe"
          :taxRate="purchContract.form.taxRate" @handleTotalAmount="handlePurchTotalAmount">
        </RelatedDevices>
        <AttachmentInfo :provideAnnexList="purchContract.annexList"></AttachmentInfo>
        <div class="common text">
          <span class="title">合同备注</span>
          <a-input v-model="purchContract.remark" placeholder="请输入" allowClear style="width: 100%;"></a-input>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button @click="back">
        取消
      </a-button>
      <a-button v-if="step === 'second'" @click="onSubmit(true)">
        保存草稿
      </a-button>
      <a-button @click="handleViewContract">
        合同预览
      </a-button>
      <!--<a-button v-if="step === 'first'" @click="handleSkip">
        跳过
      </a-button>-->
      <a-button v-if="step === 'first'" type="primary" @click="handleStep('second')">
        下一步
      </a-button>
      <a-button v-if="step === 'second'" @click="handleStep('first')">
        上一步
      </a-button>
      <a-button v-if="step === 'second'" type="primary" @click="onSubmit(false)">
        提交
      </a-button>
    </div>
    <a-modal v-model="visible" title="合同预览" :width="900" :bodyStyle="{ padding: '0 12px' }" centered>
      <MaintenanceContract :contractData="contractData"></MaintenanceContract>
      <template slot="footer">
        <span></span>
      </template>
    </a-modal>
  </div>
</template>

<script>
import invoiceTypeEnumMap, { InvoiceTypeEnum } from '@/enums/InvoiceTypeEnum';
import PurchasePaymentMethodEnumMap from '@/enums/PurchasePaymentMethodEnum';
import { newSaleContract, saleContractDetail, purchaseContractDetail, updateContract } from '@/api/contract';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import MaintenanceContract from '@/components/fileTemplates/maintenanceContract.vue';
import ContractBaseInfo from './ContractBaseInfo.vue';
import AttachmentInfo from './AttachmentInfo.vue';
import CollectionPlanInfo from './CollectionPlanInfo.vue';
import RelatedDevices from './RelatedDevices.vue';
import { numberTranToUpperCN, numberTranToCN } from '@/utils/chinesePrice';

import _ from 'lodash';

const THREE_DAYS_MILLSECONDS = 3 * 24 * 3600 * 1000;

const YEAR_MILLSECONDS = 24 * 3600 * 1000 * 365;

export default {
  name: 'NewSaleContract',
  components: {
    MaintenanceContract,
    ContractBaseInfo,
    AttachmentInfo,
    CollectionPlanInfo,
    RelatedDevices,
  },
  props: {
    businessType: {
      type: String,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      default: 'first',
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    bankInfo: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'taxRateCache',
      'supplierCache',
      'rateCache',
    ]),
  },
  data () {
    return {
      visible: false,
      InvoiceTypeEnum,
      PurchasePaymentMethodEnumMap,
      wrapperCol: { span: 16 },
      saleContract: {
        form: {
          customerHead: undefined,
          accountingUnit: undefined,
          finalCustomerName: undefined,
          invoiceType: 'DEDICATED',
          tariff: '6%',
          totalAmount: undefined,
          saleContractDate: undefined,
          salesmanName: this.userInfo.name,
          signedDate: undefined,
          paymentMethod: 2, // 默认背靠背
          bankAccounts: undefined,
          name: undefined,
        },
        paymentPlanList: [],
        deviceList: [],
        additionalDeviceSystemServe: undefined,
        annexList: [],
        remark: undefined,
      },
      purchContract: {
        form: {
          invoiceType: 'DEDICATED',
          taxRate: undefined,
          totalAmount: undefined,
          bankAccounts: undefined,
          businessType: undefined,
          templateType: undefined,
          accountingUnit: undefined,
          supplierId: undefined,
          supName: undefined,
          finalCustomerName: undefined,
          effectiveDate: undefined,
          contractDate: undefined,
          salesmanName: undefined,
          signedDate: undefined,
          payWay: undefined,
          name: undefined,
        },
        paymentPlanList: [],
        deviceList: [],
        additionalDeviceSystemServe: undefined,
        annexList: [],
        remark: undefined,
      },
      purchContractRules: {
        invoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'change' },
        ],
        totalAmount: [
          { required: true, message: '请输入合同金额', trigger: 'blur' },
        ],
        bankAccounts: [
          { required: true, message: '请输入收款账号', trigger: 'blur' },
        ],
      },
      collectionTotalAmount: -1,
      contractData: {
        partA: {},
        partB: {},
        effectiveDate: ['', '', ''],
        terminalDate: ['', '', ''],
        paymentList: [],
        paymentDays: '',
        deviceList: [],
        additionalDeviceSystemServe: '',
        totalAmountPerYear: '',
        chineseTotalAmountPerYear: '',
        totalAmount: '',
        taxRate: '',
        chineseTotalAmount: '',
        bankName: '',
        bankAccount: '',
        invoiceTypeName: '',
        attachmentList: [],
      },
      customer: {},
      customerId: '',
    };
  },
  methods: {
    handleCustomerId (_customerId) {
      this.customerId = _customerId;
    },
    handleTotalAmount (list) {
      const totalAmount = list.reduce((pre, cur) => {
        return pre + (cur.taxAmount ? cur.taxAmount * 1 : 0) * 100;
      }, 0);

      this.collectionTotalAmount = totalAmount ? totalAmount / 100 : -1;

      this.$refs.contractBaseInfo.form.totalAmount = totalAmount / 100;
    },
    handleInvoiceChange (v) {
      if (v.includes('GENERAL')) { // 发票为普通类型
        this.$refs.purchRelatedDevices.handleTaxAmountChange(true);
      } else {
        this.$refs.purchRelatedDevices.handleTaxAmountChange();
      }
    },
    handlePurchTotalAmount (list, isGeneralInvoice) {
      this.purchContract.form.totalAmount = 0;

      list.forEach(v => {
        const tariff = v.originTaxRate ? v.originTaxRate.replace('%', '') * 1 : 0; // 销售税率
        const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate.replace('%', '') * 1 : 0; // 采购税率
        const rate = (100 - tariff) / (100 - taxRate);

        v.taxAmount = parseInt(Math.round(v.originTaxAmount * (1 - this.rateCache) * rate * 100)) / 100;

        this.purchContract.form.totalAmount += v.taxAmount * 100;
      });

      const lastIndex = this.$refs.purchCollectionPlanInfo.form.paymentPlanList.length - 1;
      let val = this.purchContract.form.totalAmount / 100;

      this.$refs.purchCollectionPlanInfo.form.paymentPlanList.forEach((v, index) => {
        v.paymentAmount = parseInt(Math.round(v.originPaymentAmount * (this.purchContract.form.totalAmount / this.saleContract.form.totalAmount))) / 100;

        if (index === lastIndex) {
          v.paymentAmount = parseInt(Math.round(val * 100)) / 100;
        } else {
          val -= v.paymentAmount;
        }
      });

      this.purchContract.form.totalAmount = this.purchContract.form.totalAmount / 100;
    },
    updateRelatedDevicesRate (taxRate) {
      this.saleContract.form.tariff = taxRate;

      this.$refs.relatedDevices.form.deviceList.forEach(d => {
        d.taxRate = taxRate;
      });
    },
    updateRelatedPurchDevicesRate (taxRate) {
      this.$refs.purchRelatedDevices.form.deviceList.forEach(d => {
        d.taxRate = taxRate;
      });

      this.$refs.purchRelatedDevices.handleTaxAmountChange(this.purchContract.form.invoiceType.includes('GENERAL'));
    },
    formatDate (text, format = 'YYYY-MM-DD', defaultV = '/') {
      return text ? Moment(text * 1).format(format) : defaultV;
    },
    back (refresh = false) {
      this.$tabs.close({
        to: '/salesmanagement/salescontracts/list',
        refresh,
      });
    },
    getTotalAmountPerYear (totalAmount, contractDate) {
      let ret = '';

      if (totalAmount && contractDate && contractDate.length) {
        ret = parseInt(Math.round(totalAmount / (contractDate[1] - contractDate[0]) * YEAR_MILLSECONDS * 100)) / 100;
      }

      return ret;
    },
    getDays (t1, t2) {
      t2 = t2 || Moment(Moment().format('YYYY-MM-DD')).valueOf();

      return parseInt((t1 - t2) / 3600 / 24 / 1000) + 1;
    },
    setSaleContractContent () {
      const totalAmountPerYear = this.getTotalAmountPerYear(this.$refs.contractBaseInfo.form.totalAmount, this.$refs.contractBaseInfo.form.saleContractDate);

      this.contractData = {
        partA: {
          name: this.$refs.contractBaseInfo.form.customerHead || '',
          finalCustomerName: this.$refs.contractBaseInfo.form.finalCustomerName || '',
          legalPerson: '',
          address: `${this.customer.provinceName || ''}${this.customer.cityName || ''}${this.customer.areaName || ''}${this.customer.address || ''}`,
          postCode: '',
          telephone: '',
          fax: '',
        },
        partB: {
          name: this.$refs.contractBaseInfo.form.accountingUnit || '',
          legalPerson: this.supplierCache.legalPerson || '',
          address: `${this.supplierCache.provinceName || ''}${this.supplierCache.cityName || ''}${this.supplierCache.areaName || ''}${this.supplierCache.address || ''}`,
          postCode: this.supplierCache.postcode || '',
          telephone: this.supplierCache.telePhone || '',
          fax: this.supplierCache.fax || '',
        },
        effectiveDate: this.$refs.contractBaseInfo.form.saleContractDate?.[0] ? this.formatDate(this.$refs.contractBaseInfo.form.saleContractDate?.[0], 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        terminalDate: this.$refs.contractBaseInfo.form.saleContractDate?.[1] ? this.formatDate(this.$refs.contractBaseInfo.form.saleContractDate?.[1], 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        paymentList: this.$refs.collectionPlanInfo.form.paymentPlanList.map((v, index) => ({
          ...v,
          index: numberTranToCN(index + 1),
          paymentDateArray: v.paymentDate ? this.formatDate(v.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        })),
        paymentDays: this.$refs.collectionPlanInfo.form.paymentPlanList?.[0]?.paymentDate ? this.getDays(this.$refs.collectionPlanInfo.form.paymentPlanList?.[0]?.paymentDate, this.$refs.contractBaseInfo.form.signedDate) : '',
        deviceList: this.$refs.relatedDevices.form.deviceList.map(v => ({
          ...v,
          startServiceTerm: v.serviceTerm?.[0] ? this.formatDate(v.serviceTerm[0], 'YYYY-MM-DD', '') : '',
          endServiceTerm: v.serviceTerm?.[1] ? this.formatDate(v.serviceTerm[1], 'YYYY-MM-DD', '') : '',
        })),
        additionalDeviceSystemServe: this.$refs.relatedDevices.additionalDeviceSystemServe,
        totalAmountPerYear,
        chineseTotalAmountPerYear: totalAmountPerYear ? numberTranToUpperCN(totalAmountPerYear + '') : '',
        totalAmount: this.$refs.contractBaseInfo.form.totalAmount || '',
        taxRate: this.$refs.contractBaseInfo.form.tariff || '',
        chineseTotalAmount: this.$refs.contractBaseInfo.form.totalAmount ? numberTranToUpperCN(this.$refs.contractBaseInfo.form.totalAmount + '') : '',
        bankName: this.$refs.contractBaseInfo.form.bankAccounts ? this.$refs.contractBaseInfo.form.bankAccounts.split('_')[0] : '',
        bankAccount: this.$refs.contractBaseInfo.form.bankAccounts ? this.$refs.contractBaseInfo.form.bankAccounts.split('_')[1] : '',
        invoiceTypeName: this.$refs.contractBaseInfo.form.invoiceType ? invoiceTypeEnumMap[this.$refs.contractBaseInfo.form.invoiceType] : '',
        attachmentList: this.$refs.attachmentInfo.form.annexList,
      };
    },
    setPurchaseContractContent () {
      const totalAmountPerYear = this.getTotalAmountPerYear(this.purchContract.form.totalAmount, [this.purchContract.form.effectiveDate, this.purchContract.form.contractDate]);

      this.contractData = {
        partA: {
          name: this.purchContract.form.accountingUnit || '',
          finalCustomerName: this.purchContract.form.finalCustomerName || '',
          legalPerson: this.supplierCache.legalPerson || '',
          address: `${this.supplierCache.provinceName || ''}${this.supplierCache.cityName || ''}${this.supplierCache.areaName || ''}${this.supplierCache.address || ''}`,
          postCode: this.supplierCache.postcode || '',
          telephone: this.supplierCache.telePhone || '',
          fax: this.supplierCache.fax || '',
        },
        partB: {
          name: this.purchContract.form.supName || '',
          legalPerson: '',
          address: '',
          postCode: '',
          telephone: '',
          fax: '',
        },
        effectiveDate: this.purchContract.form.effectiveDate ? this.formatDate(this.purchContract.form.effectiveDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        terminalDate: this.purchContract.form.contractDate ? this.formatDate(this.purchContract.form.contractDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        paymentList: this.$refs.purchCollectionPlanInfo.form.paymentPlanList.map((v, index) => ({
          ...v,
          index: numberTranToCN(index + 1),
          paymentDateArray: v.paymentDate ? this.formatDate(v.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        })),
        paymentDays: this.$refs.purchCollectionPlanInfo.form.paymentPlanList?.[0]?.paymentDate ? this.getDays(this.$refs.purchCollectionPlanInfo.form.paymentPlanList[0].paymentDate, this.purchContract.form.signedDate) : '',
        deviceList: this.$refs.purchRelatedDevices.form.deviceList.map(v => ({
          ...v,
          startServiceTerm: v.serviceTerm?.[0] ? this.formatDate(v.serviceTerm[0], 'YYYY-MM-DD', '') : '',
          endServiceTerm: v.serviceTerm?.[1] ? this.formatDate(v.serviceTerm[1], 'YYYY-MM-DD', '') : '',
        })),
        additionalDeviceSystemServe: this.$refs.purchRelatedDevices.additionalDeviceSystemServe,
        totalAmountPerYear,
        chineseTotalAmountPerYear: totalAmountPerYear ? numberTranToUpperCN(totalAmountPerYear + '') : '',
        totalAmount: this.purchContract.form.totalAmount || '',
        taxRate: this.purchContract.form.taxRate || '',
        chineseTotalAmount: this.purchContract.form.totalAmount ? numberTranToUpperCN(this.purchContract.form.totalAmount + '') : '',
        bankName: this.purchContract.form.bankAccounts ? this.purchContract.form.bankAccounts.split('_')[0] : '',
        bankAccount: this.purchContract.form.bankAccounts ? this.purchContract.form.bankAccounts.split('_')[1] : '',
        invoiceTypeName: this.purchContract.form.invoiceType ? invoiceTypeEnumMap[this.purchContract.form.invoiceType] : '',
        attachmentList: this.purchContract.annexList,
      };
    },
    handleViewContract () {
      if (this.step === 'first') {
        this.setSaleContractContent();
      } else {
        this.setPurchaseContractContent();
      }

      this.visible = true;
    },
    handleSkip () {
      this.step = 'second';
    },
    async handleStep (step) {
      if (step === 'second') {
        await Promise.all([
          this.$refs.contractBaseInfo.validate(),
          this.$refs.collectionPlanInfo.validate(),
          this.$refs.relatedDevices.validate(),
          this.$refs.attachmentInfo.validate(),
        ]);

        this.purchContract.form.accountingUnit = this.saleContract.form.accountingUnit;
        this.purchContract.form.supName = this.userInfo.tenantName;
        this.purchContract.form.finalCustomerName = this.saleContract.form.accountingUnit;
        this.purchContract.form.effectiveDate = this.saleContract.form.saleContractDate ? this.saleContract.form.saleContractDate[0] : undefined;
        this.purchContract.form.contractDate = this.saleContract.form.saleContractDate ? this.saleContract.form.saleContractDate[1] : undefined;
        this.purchContract.form.salesmanName = this.saleContract.form.salesmanName;
        this.purchContract.form.signedDate = this.saleContract.form.signedDate;
        this.purchContract.form.payWay = this.saleContract.form.paymentMethod * 1 + 1;
        this.purchContract.form.name = this.saleContract.form.name;
        this.purchContract.form.taxRate = undefined;

        if (!this.purchContract.form.bankAccounts && this.bankInfo?.[0]?.bankOfDeposit && this.bankInfo?.[0]?.bankAccount) {
          this.purchContract.form.bankAccounts = `${this.bankInfo[0].bankOfDeposit}_${this.bankInfo[0].bankAccount}`;
        }

        this.purchContract.additionalDeviceSystemServe = this.saleContract.additionalDeviceSystemServe;
        this.purchContract.annexList = [...this.saleContract.annexList];

        this.purchContract.deviceList = _.cloneDeep(this.saleContract.deviceList);

        this.purchContract.form.totalAmount = 0;

        const isGeneralInvoice = this.purchContract.form.invoiceType.includes('GENERAL');

        this.purchContract.deviceList.forEach(v => {
          v.originTaxRate = v.taxRate;
          v.originTaxAmount = v.taxAmount;

          const tariff = v.originTaxRate ? v.originTaxRate.replace('%', '') * 1 : 0; // 销售税率
          const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate.replace('%', '') * 1 : 0; // 采购税率
          const rate = (100 - tariff) / (100 - taxRate);

          v.taxAmount = parseInt(Math.round(v.taxAmount * (1 - this.rateCache) * rate * 100)) / 100;

          this.purchContract.form.totalAmount += v.taxAmount * 100;
        });

        this.purchContract.paymentPlanList = _.cloneDeep(this.saleContract.paymentPlanList);

        const lastIndex = this.purchContract.paymentPlanList.length - 1;
        let val = this.purchContract.form.totalAmount / 100;

        this.purchContract.paymentPlanList.forEach((v, index) => {
          v.originPaymentAmount = v.paymentAmount;
          v.paymentDate = v.paymentDate * 1 + THREE_DAYS_MILLSECONDS + '';

          v.paymentAmount = parseInt(Math.round(v.paymentAmount * (this.purchContract.form.totalAmount / this.saleContract.form.totalAmount))) / 100;

          if (index === lastIndex) {
            v.paymentAmount = parseInt(Math.round(val * 100)) / 100;
          } else {
            val -= v.paymentAmount;
          }
        });

        this.purchContract.form.totalAmount = this.purchContract.form.totalAmount ? this.purchContract.form.totalAmount / 100 : undefined;
      }

      this.$emit('update:step', step);

      this.$nextTick(() => {
        document.querySelector('.step').scrollTop = 0;
      });
    },
    async getDetail () {
      const { body } = await saleContractDetail({
        id: this.$decode(this.id),
        findAnnexFlag: true,
        findPaymentPlanFlag: true,
        findDeviceFlag: true,
      });

      this.customer = {
        customerCode: body.customerCode,
        customerContact: body.customerContact,
        customerContactId: body.customerContactId,
        customerHead: body.customerHead,
        id: body.customerId,
      };

      this.collectionTotalAmount = body.totalAmount || -1;

      this.saleContract.form = {
        id: body.id,
        customerHead: body.customerHead,
        accountingUnit: body.accountingUnit,
        finalCustomerName: body.finalCustomerName,
        invoiceType: body.invoiceType,
        tariff: body.tariff || body.tariff === 0 ? parseInt(body.tariff * 100) + '%' : undefined,
        totalAmount: body.totalAmount || undefined,
        saleContractDate: [body.effectiveDate + '', body.contractDate + ''],
        salesmanName: body.salesmanName,
        signedDate: body.signedDate ? body.signedDate + '' : undefined,
        paymentMethod: body.paymentMethod,
        bankAccounts: `${body.bankOfDeposit}_${body.accounts}`,
        name: body.name,
        warrantyType: body.warrantyType || undefined,
      };

      this.saleContract.paymentPlanList = body.paymentPlanList.map(plan => ({
        ...plan,
        paymentDate: plan.paymentDate + '',
      }));
      this.saleContract.deviceList = body.deviceList.map(device => ({
        ...device,
        deviceCode: device.assetNumber,
        taxRate: device.taxRate || device.taxRate === 0 ? parseInt(device.taxRate * 100) + '%' : undefined,
        genericTerm: `${device.deviceName || '-'}/${device.deviceModel || '-'}/${device.deviceBrandName || '-'}/${device.deviceSerialNumber || '-'}`,
        serviceTerm: [device.serviceTermStart ? device.serviceTermStart + '' : undefined, device.serviceTermEnd ? device.serviceTermEnd + '' : undefined],
        uniqueValue: _.uniqueId(),
      }));
      this.saleContract.additionalDeviceSystemServe = body.additionalDeviceSystemServe;
      this.saleContract.annexList = body.annexList || [];
      this.saleContract.remark = body.contractRemarks;

      this.getPurchContractDetail(body.purchaseContractIds[0]);
    },
    async getPurchContractDetail (id) {
      const { body } = await purchaseContractDetail({
        id,
      });

      this.purchContract.form = {
        id: body.id,
        customerHead: body.customerHead,
        accountingUnit: body.accountingUnit,
        finalCustomerName: body.finalCustomerName,
        invoiceType: body.invoiceType,
        taxRate: undefined,
        totalAmount: body.totalAmount,
        saleContractDate: [body.effectiveDate, body.contractDate],
        salesmanName: body.salesmanName,
        signedDate: body.signedDate,
        payWay: body.payWay,
        bankAccounts: `${body.bankName}_${body.account}`,
        name: body.name,
        warrantyType: body.warrantyType || undefined,
      };

      this.purchContract.remark = body.remark;
    },
    async onSubmit (draftFlag) {
      const params = {
        draftFlag,
        purchContract: {
          id: this.purchContract.form.id,
          businessType: this.businessType,
          templateType: this.templateType,
          taxRate: this.purchContract.form.taxRate ? this.purchContract.form.taxRate.replace('%', '') : undefined,
          totalAmount: this.purchContract.form.totalAmount,
          bankName: this.purchContract.form.bankAccounts.split('_')[0],
          account: this.purchContract.form.bankAccounts.split('_')[1],
          accountingUnit: this.purchContract.form.accountingUnit,
          supName: this.purchContract.form.supName,
          finalCustomerName: this.purchContract.form.finalCustomerName,
          invoiceType: this.purchContract.form.invoiceType,
          effectiveDate: this.purchContract.form.effectiveDate,
          contractDate: this.purchContract.form.contractDate,
          salesmanName: this.purchContract.form.salesmanName,
          signedDate: this.purchContract.form.signedDate,
          payWay: this.purchContract.form.payWay,
          name: this.purchContract.form.name,
          annexList: this.purchContract.annexList,
          deviceList: this.purchContract.deviceList.map(v => ({
            ...v,
            serviceTermStart: v.serviceTerm[0],
            serviceTermEnd: v.serviceTerm[1],
            taxRate: v.taxRate.replace('%', ''),
          })),
          paymentPlanList: this.purchContract.paymentPlanList,
          remark: this.purchContract.remark,
          additionalDeviceSystemServe: this.purchContract.additionalDeviceSystemServe,
        },
        saleContract: {
          id: this.saleContract.form.id,
          businessType: this.businessType,
          templateType: this.templateType,
          customerHead: this.saleContract.form.customerHead,
          accountingUnit: this.saleContract.form.accountingUnit,
          finalCustomerName: this.saleContract.form.finalCustomerName,
          invoiceType: this.saleContract.form.invoiceType,
          tariff: this.saleContract.form.tariff ? this.saleContract.form.tariff.replace('%', '') : undefined,
          totalAmount: this.saleContract.form.totalAmount,
          contractDate: this.saleContract.form.saleContractDate[1],
          effectiveDate: this.saleContract.form.saleContractDate[0],
          salesmanName: this.saleContract.form.salesmanName,
          signedDate: this.saleContract.form.signedDate,
          paymentMethod: this.saleContract.form.paymentMethod,
          accounts: this.saleContract.form.bankAccounts.split('_')[1],
          bankOfDeposit: this.saleContract.form.bankAccounts.split('_')[0],
          name: this.saleContract.form.name,
          paymentPlanList: this.saleContract.paymentPlanList,
          deviceList: this.saleContract.deviceList.map(v => ({
            ...v,
            serviceTermStart: v.serviceTerm[0],
            serviceTermEnd: v.serviceTerm[1],
            taxRate: v.taxRate.replace('%', ''),
          })),
          additionalDeviceSystemServe: this.saleContract.additionalDeviceSystemServe,
          annexList: this.saleContract.annexList,
          remark: this.saleContract.remark,
        },
      };

      const api = this.id ? updateContract : newSaleContract;

      await api(params);

      this.back(true);
    },
  },
  created () {
    if (this.id) {
      this.getDetail();
    }
  },
};
</script>

<style scoped lang="scss">
.maintenance_template_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;

    .step {
      height: 100%;
      overflow: auto;
    }

    .gap-12 {
      margin-bottom: 12px;
    }

    .common {
      background-color: #fff;
      padding: 12px;
      margin-bottom: 6px;

      .title {
        font-weight: bold;
      }

      /deep/ .ant-form-item {
        margin-bottom: 8px;

        .ant-form-item-label {
          float: left;
          width: 7em;
        }

        .ant-form-explain {
          font-size: 12px;
          height: auto;
        }
      }

      &.text {
        display: flex;
        align-items: center;

        span {
          width: 5em;
        }

        .ant-input {
          flex: 1;
        }
      }

      .extra-input {
        display: flex;
        align-items: center;
        padding: 24px 0 12px 0;

        span {
          width: 9em;
        }

        .ant-input {
          flex: 1;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    background-color: #fff;
    padding: 20px;

    .ant-btn {
      margin-left: 12px;
    }
  }
}
</style>

